export default {
  "404": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error 404 - Página no encontrada"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El sitio web que buscas no pudo ser encontrado."])}
  },
  "footer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo Wallet"])}
  },
  "login": {
    "new_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva billetera"])},
    "new_wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la billetera"])},
    "new_wallet_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña para la billetera"])},
    "new_wallet_button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear billetera"])},
    "new_wallet_button_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar billetera"])},
    "new_wallet_help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su billetera se almacenará en su navegador. La contraseña es necesaria para abrir la billetera, ver las cuentas dentro y para firmar transacciones."])},
    "new_wallet_help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendamos que la contraseña tenga 12 caracteres o más, utilizando minúsculas, mayúsculas, números y caracteres especiales."])},
    "open_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir billetera"])},
    "select_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar billetera"])},
    "wallet_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña de la billetera"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortaleza"])},
    "new_wallet_button_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir billetera"])},
    "basic_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uso básico"])},
    "tether_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cómo utilizar Tether? (USDt)"])},
    "source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["código fuente de AWallet"])}
  },
  "global": {
    "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retroceder"])},
    "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "copy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar dirección"])},
    "copied_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiado al porta papeles!"])},
    "stop_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detener cámara"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando.."])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último error"])},
    "keyword_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword Search"])}
  },
  "navbar": {
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Wallet"])},
    "toggle_nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar la navegación"])},
    "list_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listar mis cuentas"])},
    "new_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva cuenta"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuraciones"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos"])},
    "asset_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear NFT"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
    "vote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votar"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INTERCAMBIO"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])}
  },
  "swap": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambiar activos"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red"])},
    "network_not_supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La red no es compatible"])},
    "has_sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora sólo se admiten cuentas estándar con claves privadas"])},
    "swap_asset_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione el activo que desea intercambiar"])},
    "swap_asset_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione el activo por el cual desea intercambiar"])},
    "swap_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establece la cantidad a intercambiar"])},
    "get_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cotizar (previsualizar el intercambio)"])},
    "apps_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INTERCAMBIO require que su cuenta haga el Opt-in a la aplicación"])},
    "apps_optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacer Opt-in a la aplicación"])},
    "execute_button_deflex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejecutar usando Deflex"])},
    "execute_button_folks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejecutar usando Folks router"])},
    "slippage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcentaje de desviación"])}
  },
  "govtoolsmenu": {
    "gen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar cuentas"])},
    "distribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuir algo 1->N"])},
    "optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacer Opt in para la gobernanza"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagos en masa N->1"])},
    "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combinar cuentas N->1"])}
  },
  "merchant": {
    "make_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasarela de pagos"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
    "to_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a la dirección Algorand"])},
    "please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por favor"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red"])},
    "matching_symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Símbolo asociado"])},
    "network_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión de red"])},
    "pay_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar usando un código QR"])},
    "pay_nativewallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar usando una billetera de Algorand"])},
    "pay_webwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar usando una billetera web"])},
    "cancel_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar pago"])},
    "payment_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago realizado"])},
    "go_back_to_merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regresar al comercio"])},
    "waiting_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperando el pago"])},
    "error_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error en la búsqueda del activo:"])}
  },
  "gateway": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasarela de pago gratuita de código abierto sin intermediarios"])},
    "perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comience a aceptar algo, USDC, USDT u otros activos en su sitio web. Aceptando criptodivisas sin intermediario financiero aumentarás las ventas en tu sitio web y aumentarás tus ganancias."])},
    "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo funciona?"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un símbolo asociado único, por ejemplo, id de pedido"])},
    "redirect_to_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirigir el usuario a la pasarela de pago"])},
    "customer_pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cliente paga directamente a su cuenta Algorand"])},
    "customer_redirected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cliente será redirigido de vuelta a su sitio web después de realizar el pago."])},
    "use_your_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliza tu dinero. Puedes intercambiar USDT y llevarte USD del intercambio, o puedes pagar con USDT otros bienes o servicios"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Es seguro?"])},
    "you_make_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted establece los detalles del pago. Usted configura la dirección URL de éxito y la de cancelación. Le notificamos cuando el pago se ha realizado. Tienes que comprobar en tu backend (donde aceptas el pago) que el pago se ha recibido y se lo notificas al cliente."])},
    "opensource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este proyecto es de código abierto. Puedes clonarlo, modificarlo o ejecutarlo en tu entorno privado con fines comerciales."])},
    "just_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La pasarela de pago es sólo un sitio web HTML con javascript. No se utiliza ningún otro backend que no sean los nodos oficiales de algorand."])},
    "no_restrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es necesario tener una cuenta AWallet para utilizar la pasarela."])},
    "allowed_parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros permitidos"])},
    "parameters_perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes configurar cualquiera de estos parámetros. Los únicos campos obligatorios son la dirección en la que deseas recibir el pago y el monto de la transacción. La dirección de pago consta de dos partes: información de pago y configuración de la pasarela. Ambos campos están codificados en base64 separados por \"/\". Por favor, asegúrese de que los codifica correctamente. La información del pago que se utiliza, es la dirección básica del protocolo Algorand, por ejemplo, la que se usa  en los códigos QR."])},
    "payment_info_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros permitidos"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilizar la unidad base del activo. Por ejemplo, 1 ALGO = 1000000"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la cantidad en microalgos. Por ejemplo: 0,001 ALGO = 1000"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red de depuración (pruebas)"])},
    "settings_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes permitidos"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección URL a la que se redirige al cliente una vez efectuado el pago satisfactoriamente."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección URL a la que se redirige al cliente tras hacer clic en cancelar pago"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejemplo"])},
    "turn_on_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar la herramienta de compilación"])},
    "turn_off_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivar la herramienta de compilación"])},
    "tether": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tether mainnet"])},
    "algorand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede especificar el identificador de activo para la transacción"])},
    "error_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rellene al menos la cuenta, el monto y el activo"])},
    "error_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La dirección URL DEBE comenzar con: https://"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botón generado"])},
    "payment_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del pago"])},
    "callback_configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración del callback"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar link"])},
    "html_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejemplo HTML del link"])}
  },
  "receive": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibir el pago"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibir el pago en la dirección"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información sobre los decimales del código QR"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiqueta sugerida de la dirección del receptor"])},
    "noteeditable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El usuario puede editar el campo Nota"])}
  },
  "acc_overview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista general de la cuenta"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacer un nuevo pago"])},
    "receive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibir pago"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
    "amount_without_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto sin recompensas pendientes"])},
    "rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recompensas"])},
    "pending_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recompensas pendientes"])},
    "reward_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base de la recompensas"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ronda"])},
    "apps_local_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado local de las apps"])},
    "apps_total_schema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esquema total de las apps"])},
    "assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos"])},
    "created_apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps creadas"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "multisignature_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbral multifirma"])},
    "multisignature_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direcciones multifirma"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recargar"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transacciones"])},
    "no_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ninguna transacción"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo"])},
    "tr_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remitente"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receptor"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recompensas del receptor"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ronda conFeefirmada"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar cuenta"])},
    "delete_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar cuenta"])},
    "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres eliminar esta cuenta de tu Billetera?"])},
    "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar esta cuenta"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cuenta se ha eliminado correctamente"])},
    "asset_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacer Opt in al activo"])},
    "ask_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacer una pregunta"])},
    "rekey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re asignar llaves a una cuenta (Rekey)"])},
    "rekeyedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llaves re asignadas a la cuenta (Rekeyed)"])},
    "account0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta primaria del Ledger"])},
    "slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta en la ranura del ledger"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])},
    "payment_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasarela de pagos"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
    "no_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no assets yet on this account"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "pay_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send money or tokens to other accounts in the network."])},
    "swap_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can swap one token to another using DEX aggregator services"])},
    "rekey_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are afraid that your account private key may be compromise, you can rekey your account to new private key and keep your original address safe."])},
    "asset_optin_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to receive asset on algorand network you have to open the account for that token."])},
    "receive_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can ask your customers or friends to send you money. This page will generate payment QR code so that others can do it on one click."])},
    "export_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can generate shamir backup mnemonics or algorand mnemonic for accounts with private keys."])},
    "arc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC 14 Authentication"])},
    "arc14_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC 14 is authentication standard utilizing signed transactions. You can generate the authentication tokens on this page."])},
    "account_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account actions"])},
    "asset_optinArc200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OptIn to ARC200 asset"])},
    "asset_optinArc200_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can watch on your account the ARC200 assets. ARC200 assets are smart contracts with feature that anyone can opt in user's account to be able to receive the asset. ARC200 assets may be dangerous and you should require trustworthy audit of the smart contract so that you can fully trust it."])},
    "scheduled_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payment"])},
    "scheduled_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can setup and manage a scheduled periodic payments to another accounts"])},
    "hide_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide account"])},
    "unhide_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unhide account"])},
    "vote_first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote First Round"])},
    "vote_last_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Last Round"])},
    "vote_key_dilution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Key Dilution"])},
    "selection_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selection Key"])},
    "vote_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Key"])},
    "stateproof_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StateProof Key"])},
    "realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realm"])},
    "arc14msig_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please sign ARC14 authentication transaction and return back to this form."])},
    "hasArc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC14 auth has been loaded."])},
    "generating_keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generating participation keys.. Please be patient, the participation node is performing CPU sensitive task."])},
    "button_sign_keyreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign key registration tx"])},
    "button_close_custom_keyreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close KeyReg tx form"])},
    "button_activate_wizzard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate wizard"])},
    "button_fetch_realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 1/4: Fetch ARC14 realm"])},
    "button_sign_arc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2/4: Sign ARC14 realm"])},
    "button_sign_arc14_msig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2/4: Multisign ARC14 realm"])},
    "button_load_participatin_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 3/4: Load participation data"])},
    "button_sign_keyreg_tx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 4/4: Sign participation tx"])},
    "button_custom_keyreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom KeyReg tx"])},
    "making_account_online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting your account to online state. Please wait a while"])},
    "making_account_offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting your account to offline state. Please wait a while"])}
  },
  "acc_overview_assets": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "search_by_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "search_by_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by asset type"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
    "search_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by asset id"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "search_by_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by amount"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
  },
  "optin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar transferencias de activos en cuenta"])},
    "searchById": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conozco el ID del activo"])},
    "assetId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del activo"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del activo"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar activo"])},
    "assetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del activo"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "unit_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit name"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimales"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "metadata_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash de la metadata"])},
    "default_frozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congelado por defecto"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección del creador"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección del administrador"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de revocación"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de congelador"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de la reserva"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acción"])},
    "optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in"])},
    "dialog_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacer Opt in al activo"])},
    "optin_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que desea pagar 0,001 algo para hacer Opt-in a este activo? Después del Opt-in, usted podrá de recibir este activo en su cuenta."])},
    "optin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiero hacer el Opt-in"])},
    "asset_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo no encontrado"])},
    "asset_opt_in_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se hizo el Opt-in satisfactoriamente"])}
  },
  "accounts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista general de la cuenta"])},
    "no_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron cuentas"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la cuenta"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])},
    "show_on_netowork_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar cuentas para la red"])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código 2FA"])}
  },
  "newacc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva cuenta"])},
    "create_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una cuenta básica"])},
    "import_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar cuenta"])},
    "create_multisign_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta multifirma"])},
    "watch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigilar cuenta"])},
    "write_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba la frase mnemotécnica de 25 palabras"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre interno de la cuenta"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "multisig_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cuenta multifirma sólo puede procesar transacciones si N cuentas incluidas en la creación de la cuenta firman la transacción."])},
    "select_account_from_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione las cuentas existentes en su billetera"])},
    "add_other_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añade las cuentas de tus amigos - una cuenta por línea"])},
    "trashold_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione cuántas cuentas son necesarias para firmar la transacción"])},
    "create_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para crear la cuenta, asegúrese de hacer una copia de seguridad de la frase mnemotécnica. ¿Es seguro mostrar la frase ahora?"])},
    "show_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar mnemotécnica"])},
    "position_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuál es la palabra en la posición n."])},
    "mnemonic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba las palabras mnemotécnicas en el orden indicado, cópielas o hágales una foto. El siguiente paso será confirmar algunas de las palabras de esta frase y la guardaremos en el almacenamiento cifrado de su navegador. Es seguro guardar esta mnemotécnica y utilizar esta dirección sin almacenarla en la cuenta. Asegúrese de no perder nunca la frase mnemotécnica."])},
    "start_challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar el desafío"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nueva"])},
    "hide_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar mnemotécnica"])},
    "drop_phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introducir frase"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanear desde código QR"])},
    "create_vanity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta de vanidad"])},
    "vanity_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La dirección comenzará con"])},
    "vanity_mid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La dirección contendrá"])},
    "vanity_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La dirección terminará con"])},
    "vanity_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de direcciones procesadas"])},
    "vanity_button_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontrar la dirección"])},
    "vanity_button_stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deja de buscar"])},
    "vanity_workers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de hilos a utilizar"])},
    "vanity_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizar esta cuenta"])},
    "auto_lock_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La función de bloqueo automático está desactivada"])},
    "ledger_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HW Wallet - Cuenta Ledger"])},
    "wc_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta Wallet Connect"])},
    "twofa_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta 2FA"])},
    "show_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar código QR"])},
    "email_pass_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de correo y contraseña"])},
    "shamir_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir copia de seguridad de recuperación"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New account actions"])},
    "create_basic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand basic account is ED25519 account with private key. This method uses your computer random number generator to create secret for your account."])},
    "ledger_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of most secure solutions is to handle the secret key generation by specialized device. This account utilizes Ledger device to create your account and handle the signing operations."])},
    "wc_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can connect to your friend's wallet using the wallet connect account type. You can send them request to sign the message for example as part of multisig."])},
    "twofa_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two factor account is multisig account, where one account is account of your choosing for example ledger account or email password account, second account is recovery account - ideally account which is not in the same secure storage, and third account is the two factor account from the server which signs transactions when you provide correct two factor pin code."])},
    "email_pass_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC76 Email & Password account allows you to generate algorand account from any text. The text is hashed and generates 32 bytes as seed for your algorand account. This may be alternative to other types of random number generators in your computer or hw device which you can utilize in two factor account or generic multisig account."])},
    "create_vanity_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can brute force nice account address for your ED25519 account. This method simply tries a lot of combinations of creating account and lets you pick first or last letters of the account address."])},
    "create_multisign_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisig accounts are the most secure accounts. If used in combination of multiple ledger devices and different random number generators, this provides enterprise level of security which is hardcoded directly to the algorand blockchain."])},
    "watch_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can track any account on the network."])},
    "import_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can recover the account from the algorand recovery mnemonic."])},
    "shamir_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can recover the account from the shamir backup recovery mnemonics."])},
    "create_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your first account"])},
    "no_accounts_at_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no accounts for selected network"])},
    "basic_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic options"])},
    "advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced options"])},
    "skip_challange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip challange"])},
    "new_account_challange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New account challange"])},
    "click_to_show_positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to show positions"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])}
  },
  "arc76account": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de correo y contraseña (ARC-0076)"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El hash de la combinación de tu correo electrónico y tu contraseña generará bytes semilla para tu primera cuenta Algorand. Puede cambiar la contraseña o el correo electrónico sólo si re asigna las llaves de su cueta (Rekey)."])},
    "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se utilizará el estándar ARC-0076 con el algoritmo PBKDF2-999999-SHA256. Recomendamos que la longitud de la contraseña sea de al menos 50 caracteres. Este tipo de cuenta puede servir como tipo secundario de generador de números aleatorios y puede reforzar su seguridad. Por favor, mantenga el secreto seguro y no lo utilice multiples veces."])},
    "arc_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC-0076 se encuentra en estado BORRADOR. La especificación puede cambiar. Por favor, considere esta implementación para uso experimental."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "select_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, seleccione una contraseña MUY MUY MUY segura."])},
    "save_password_switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar contraseña"])},
    "password_not_stored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuidado. No almacenamos su contraseña. Sólo almacenaremos la dirección pública de la cuenta y el correo electrónico en el almacenamiento seguro. Cuando realice transacciones con esta cuenta, se le pedirá que introduzca la contraseña."])},
    "gui_not_implemented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La interfaz gráfica de usuario para firmar transacciones sin contraseña almacenada no está implementada actualmente."])}
  },
  "import": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar billetera"])},
    "wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la billetera"])},
    "wallet_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiv o de la billetera"])},
    "import_wallet_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar billetera"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede importar billeteras previamente exportadas. La contraseña de la billetera permanece dentro del archivo exportado, por lo que puede acceder a ella después de la importación con la misma contraseña que antes. Puedes cambiar la contraseña después de acceder desde la página de configuración."])}
  },
  "acc_type": {
    "basic_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta básica"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta multifirma"])},
    "public_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta pública"])},
    "rekeyed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta con llaves re asignada (Rekeyed)"])},
    "ledger_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta HW ledger"])},
    "wc_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta WalletConnect"])}
  },
  "account": {
    "select_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar cuenta"])}
  },
  "pay": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizar el pago - desde"])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar de cámara"])},
    "note_is_b64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La nota está codificada en Base64. Si está activada, puede introducir datos base64 en el campo de nota y enviarlos como datos sin procesar a la red."])},
    "selected_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta seleccionada"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta multifirma"])},
    "create_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear propuesta"])},
    "sign_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmar y enviar propuesta"])},
    "signature_from_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca aquí la firma de su amigo"])},
    "load_multisig_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar datos de multifirma"])},
    "pay_to_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar a la cuenta de billetera"])},
    "pay_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar a otra cuenta"])},
    "store_other_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es mejor guardar la cuenta a la que envías dinero en la libreta de direcciones"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota"])},
    "review_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar pago"])},
    "review_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, revise su pago"])},
    "from_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde cuenta"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar a la cuenta"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la transacción"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primera ronda"])},
    "last_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última ronda"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Génesis ID"])},
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiqueta"])},
    "to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la cuenta"])},
    "signatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmantes"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmado"])},
    "not_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No firmado"])},
    "sign_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmar con"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmar"])},
    "send_to_other_signators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar estos datos a otros firmantes"])},
    "send_to_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar a la red"])},
    "state_sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar el pago a la red"])},
    "state_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago enviado a la red. Transc: "])},
    "state_waiting_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperando confirmación de la red."])},
    "state_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha recibido la confirmación. Su pago está en el bloque"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
    "state_error_not_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probablemente el pago no ha llegado a la red. ¿Está desconectado? Compruebe su cuenta"])},
    "set_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escoger máximo"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambiente"])},
    "pay_to_address_malformed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La dirección de pago parece estar malformada."])},
    "asset_failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece que el activo deseado no se ha podido cargar"])},
    "asset_too_small_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece que su saldo es inferior a la cantidad de pago deseada"])},
    "rekey_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re asiganar las llaves a la cuenta"])},
    "rekey_to_wallet_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re asiganar las llaves de la cuenta de billetera"])},
    "rekey_to_external_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re asiganar las llaves de la cuenta externa"])},
    "rekey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re asiganar las llaves de la cuenta"])},
    "rekey_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, preste atención especial a esta transacción. A partir de ahora, la cuenta utilizará una clave privada diferente para poder realizar transacciones. Si no dispone de la clave privada de la nueva cuenta, lo más probable es que pierda la posibilidad de firmar transacciones."])},
    "combine_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combinar las firmas de otros firmantes"])},
    "combine_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combinar las firmas"])},
    "return_to_wc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regresar a WalletConnect"])},
    "select_account_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione la cuenta desde la que desea realizar el pago"])},
    "create_multisig_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear propuesta multifirma"])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código 2FA"])},
    "sign_arc14_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmar la solicitud de autorización"])},
    "toggle_send_to_others_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar el formulario Enviar a otros"])},
    "toggle_combine_with_others_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar formulario Combinar con otros"])},
    "process_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesar pago"])},
    "tx_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de transacción"])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuraciones"])},
    "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servidor"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambiente"])},
    "mainnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mainnet"])},
    "testnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testnet"])},
    "devnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apuntar a Devnet"])},
    "sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["host"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["token"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lenguaje"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña de la billetera"])},
    "oldpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua contraseña"])},
    "newpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
    "repeatpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar nueva contraseña"])},
    "backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacer copia de seguridad de la billetera"])},
    "backup_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes hacer una copia de seguridad de tu billetera e importarla a otra computadora."])},
    "create_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear copia de seguridad"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar billetera"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortaleza"])},
    "updated_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña actualizada"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración personalizada del servidor"])},
    "protocol_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar controlador de protocolo"])},
    "protocol_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar web+algorand:// controlador"])},
    "protocol_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha actualizado correctamente el controlador de protocolor"])},
    "dev_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar información para desarrolladores"])}
  },
  "transaction": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalle de la transacción"])},
    "tr_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de transacción"])},
    "tr_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de transacción"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota"])},
    "note_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos en bruto"])},
    "note_decoded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos descodificados"])},
    "tr_close_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar recompensas"])},
    "closing_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto final"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ronda confirmada"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión"])},
    "first_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primer válido"])},
    "genesis_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de Génesis"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash del Génesis"])},
    "intra_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplazamiento dentro de la ronda"])},
    "last_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último válido"])},
    "payment_tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción de pago"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
    "close_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto de cierre"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receptor"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recompensas para el/los receptor(es)"])},
    "round_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de ronda"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remitente"])},
    "sender_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recompensas para el/los remitente(s)"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
    "created_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo creado"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del activo"])},
    "asset_creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creador del activo"])},
    "asset_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador del activo"])},
    "asset_reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva del activo"])},
    "asset_freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congelador del activo"])},
    "asset_clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revocador de activos"])},
    "asset_unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre unitario del activo"])},
    "asset_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total del activo"])},
    "asset_decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimales del activo"])},
    "asset_defaultfrozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo congelado por defecto"])},
    "asset_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Url del activo"])},
    "asset_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata del activo"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del grupo"])}
  },
  "privacy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué información privada recopilan sobre mí?"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ninguno. Aparte de la dirección IP y el navegador, que se almacenan en los registros de todos los servicios de Internet, no recopilamos ningún tipo de información privada como su nombre, correo electrónico o dirección."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué se almacena en las cookies?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nada. No utilizamos cookies. Utilizamos almacenamiento local persistente llamado IndexedDB para el almacenamiento de su billetera."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo se recopila la información de analítica?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No lo hacemos. No utilizamos ningún tipo de servicio de análisis, por ejemplo google analytics, para que usted se sienta más seguro. El sitio web es puramente HTML con javascript. Sólo conocemos su dirección IP cuando realiza peticiones al servidor. Dependiendo de su configuración y de su servidor algod o indexador, el servidor seleccionado también puede conocer su dirección IP."])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿El uso de este servicio afecta los anuncios que veo?"])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Los servidores de anuncios no tienen ninguna información nuestra sobre ningún uso. La única forma en que, por ejemplo, google, puede saber que está utilizando este servicio es si accede a este sitio desde la búsqueda de google."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quién presta este servicio?"])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scholtz & Company, jsa ha creado este servicio. Id. de empresa: 51 882 272, IVA ID: SK2120828105, con sede en Bratislava, Eslovaquia.  Si esto se ejecuta en el sitio web oficial a-wallet.net, la empresa es también el proveedor de servicios. La empresa no se hace responsable de ningún servicio, ni de los errores en la aplicación."])}
  },
  "assetcreate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo NFT / ASA"])},
    "show_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar formulario avanzado"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre amistoso"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección del creador"])},
    "hide_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar formulario avanzado"])},
    "creator_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear dirección o importar dirección primero"])},
    "default_fronzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congelar: Las cuentas de usuario tendrán que ser descongeladas antes de realizar transacciones."])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimales: número entero de decimales para el cálculo de la unidad de activos."])},
    "totalIssuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número total de este activo disponible para la circulación"])},
    "unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se utiliza para mostrar las unidades de activos al usuario"])},
    "assetURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL opcional relacionada con el activo"])},
    "assetMetadataHash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash de compromiso opcional de algún tipo relacionado con el activo. Formato Base64"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El administrador puede modificar la cuenta de administrador, reserva, congelación y recuperación del activo."])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de reserva: La dirección especificada se considera la reserva de activos (no tiene privilegios especiales, esto es sólo informativo)"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de congelación: La dirección especificada puede congelar o descongelar las tenencias de activos del usuario"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de revocación: La dirección especificada puede revocar las tenencias de activos del usuario y enviarlas a otras direcciones"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota establecida en la transacción de creación"])},
    "create_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear NFT / ASA"])}
  },
  "new_account_ledger": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar cuenta Ledger"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledger es un dispositivo físico - billetera HW que puede almacenar su clave privada. La máxima seguridad para almacenar los activos en algorand es cuenta multifirma con todos los firmantes asegurados por dispositivos ledger."])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último error"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar Ledger y cargar dirección Algorand"])},
    "slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranura"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "primary_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección principal"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la cuenta"])},
    "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar la dirección en la billetera"])}
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas frecuentes"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de AWallet"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo Waltera fue creada inicialmente por Scholtz & Company, jsa. Código abierto, sólo HTML, amigable para las empresas, y la seguridad son las principales ventajas para su uso. El logotipo de Algorand, las palabras \"algo\" y \"algorand\" son marcas registradas de Algorand, Inc. y se utilizan con las directrices de algorand media https://www.algorand.com/about/media-kit/ ."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo se protegen los datos de mi billetera?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus datos sólo están en el navegador. Se almacenan en IndexedDB persistente. Cada billetera es encriptada usando aes256 con tu contraseña. Si creas una contraseña lo suficientemente fuerte, tu billetera nunca será vulnerada por un ataque de fuerza bruta. Su contraseña no sale de su ordenador y sólo reside en la caché del navegador. La caché se destruye en 5 minutos después del inicio de sesión."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿La contraseña de mi billetera sale a Internet?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nunca"])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo puedo cambiar la contraseña de mi billetera?"])},
    "a4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sesión y accede a tu billetera."])},
    "a4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navega hasta los ajustes."])},
    "a4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba la contraseña antigua, la contraseña nueva y confirme la contraseña nueva."])},
    "a4_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envíe el formulario y se cambiará su contraseña."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Puedo trasladar mi billetera a otro ordenador?"])},
    "a5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sesión y accede a tu billetera."])},
    "a5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navega hasta los ajustes."])},
    "a5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ve a la sección de copias de seguridad y guarda tus datos en un archivo local."])},
    "a5_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el otro ordenador ve a nueva billetera, importar billetera, y sube el archivo descargado al almacenamiento del otro navegador."])},
    "a5_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accede a la billetera con la misma contraseña que antes. Puedes cambiar la contraseña en los ajustes."])},
    "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Puedo revisar el código fuente?"])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí. https://github.com/scholtz/wallet/ Esta billetera fue hecha de código abierto y es libre de ser usada, copiada o modificada por cualquier razón, incluyendo propósitos comerciales. Si usted puede participar en el proyecto, por favor amablemente cree un pull request con su mejora."])},
    "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo puedo ayudar al proyecto?"])},
    "a7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doanndo a la dirección de algorand AWALLETCPHQPJGCZ6AHLIFPHWBHUEHQ7VBYJVVGQRRY4MEIGWUBKCQYP4Y ayudará a que el proyecto siga adelante. El proyecto no ha sido financiado por Algorand Inc. ni por la Fundación Algorand."])},
    "a7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para traducir el proyecto o ayudar con la traducción, por favor vaya a https://github.com/scholtz/wallet/tree/master/src/locales, copie el archivo de traducción a su idioma y haga un pull request."])},
    "a7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si eres programador, por favor haz una revisión del código, o ayúdanos de cualquier forma que creas que este proyecto debería mejorar."])},
    "q8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo puedo crear una cuenta multifirma?"])},
    "a8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primero crea una cuenta con claves privadas o crea una cuenta pública. Es mejor darles nombres que le ayudarán a recordar su propósito."])},
    "a8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaya a crear una nueva dirección y seleccione dirección multifirma."])},
    "a8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione todas las cuentas que pueden firmar las transacciones y seleccione cuántas firmas se necesitan mínimo para que la red acepte el pago multifirma."])},
    "a8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una vez creada la cuenta multifirma, puede acceder a ella desde la página de la lista de cuentas."])}
  },
  "connect": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar con las DApps mediante WalletConnect"])},
    "uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect URI"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar"])},
    "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect"])},
    "sendBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a la DApp"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o"])},
    "authaddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección autorizada"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Índice"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
    "rekeyto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llaves re asignadas a"])},
    "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método"])},
    "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del cliente"])},
    "request_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de la solicitud"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover"])},
    "peer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par"])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectado"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiones"])},
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitudes"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión"])},
    "total_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión total"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmar"])},
    "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectarse desde el portapapeles"])},
    "sign_txs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, firme las transacciones individuales"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmado"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])},
    "validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validez"])},
    "rounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rondas"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
    "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["índice de la App"])},
    "app_args": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argumentos de la App"])},
    "app_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas de la App"])},
    "app_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos externos de la App"])},
    "boxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas (Boxes)"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de Génesis"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash de Genesis"])},
    "session_proposals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propuestas de sesión"])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar de cámara"])},
    "init_wc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicializar la conexión con WalletConnect"])},
    "sign_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmar todo"])},
    "proposer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proposer"])},
    "all_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use all wallet accounts"])}
  },
  "new_account_wc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir cuenta WalletConnect"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la cuenta"])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último error"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanear el código QR"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar la dirección en la billetera"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar URI al portapapeles"])},
    "show_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar código QR"])}
  },
  "new_account_2fa": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta 2FA"])},
    "help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cuenta 2FA le permite crear una cuenta multifirma con 3 cuentas configuradas y un umbral de 2 firmas requeridas."])},
    "help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La primera cuenta puede ser una cuenta protegida por contraseña donde en caso de hackeo de su billetera, el hacker no pueda firmar las transacciones en su nombre. Si su dispositivo 2FA se rompe, o el servicio 2FA se interrumpe, puede re asignar las llaves de su cuenta a otra 2FA multifirma con su primera y segunda cuenta."])},
    "help3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El servicio 2FA es un servicio de terceros que puede verse comprometido. Si un hacker obtiene su clave privada y obtiene la clave privada del servicio 2FA, podrá firmar transacciones en su nombre. Al utilizar el servicio 2FA, aumenta la posibilidad de que el hacker que obtenga la capacidad de firmar transacciones con su cuenta principal o de recuperación no pueda firmar transacciones desde su cuenta multifirma."])},
    "2fa_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["proveedor 2FA"])},
    "hot_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta caliente"])},
    "cold_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de almacenamiento en frío"])},
    "request_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar código QR 2FA"])},
    "scan_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanee el código QR con su aplicación 2FA"])},
    "manual_entry_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introducir clave manualmente"])},
    "confirm_2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar configuración"])},
    "confirm_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba el código 2FA de su aplicación de autenticación para confirmar la configuración. Después de la confirmación no es posible solicitar el código secreto 2FA de nuevo."])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código 2FA"])},
    "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar dirección"])}
  },
  "onlineofflinedialog": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proteja la red con el saldo de su cuenta"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede seleccionar el nodo de participación pública para verificar los bloques en la red. Usted generará nuevas claves de participación en el servidor algod público y firmará la transacción para permitir que estas claves de participación recopilen los datos de los bloques. Esto le costará como una transacción en la red (0,001 algo). El estado de cuenta en línea no significa que su clave privada esté expuesta a Internet. Significa que su cuenta está registrada en el nodo algod online que está protegiendo blockchain."])},
    "makeOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver la cuenta \"online\""])},
    "makeOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver la cuenta \"offline\""])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algod anfitrión"])}
  },
  "new_account_shamir": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia de seguridad Shamir de recuperación"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La copia de seguridad Shamir es el método para dividir la mnemotécnica en N partes, donde M cuentas es el umbral de recuperación."])},
    "mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mnemotécnica"])},
    "add_mnemnic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir mnemotécnica"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la cuenta"])},
    "recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar"])}
  },
  "account_export": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar cuenta"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En esta página puedes exportar tu clave privada en forma de mnemotécnica de algorand o copia de seguridad shamir."])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca la contraseña de su billetera"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "algo_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mnemotécnica de Algorand"])},
    "shamir_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copia de seguridad Shamir"])},
    "toggle_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar código QR"])},
    "recovery_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperación de umbral"])},
    "number_of_mnemonics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero de la mnemotécnica"])},
    "generate_shamir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar shamir mnemotécnica"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Índice Shamir"])},
    "shamir_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, asegure la frase mnemotécnica en un lugar MUY seguro. Asegúrese de anotar todas las partes de la copia de seguridad shamir y no almacenar el threshold de recuperación en un solo lugar."])},
    "algo_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, asegure la frase mnemotécnica en un lugar MUY seguro."])},
    "dev_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del desarrollador"])}
  },
  "arc200": {
    "optin_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add ARC 200 asset to account"])},
    "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC200 App ID"])},
    "fetch_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetch asset information"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "examples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example assets"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbol"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimals"])},
    "supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total supply"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account balance"])},
    "is_opted_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account can receive asset"])},
    "create_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create box for this asset for this account"])},
    "save_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save asset to your account"])}
  },
  "scheduled_payments": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payments management"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With decentralized scheduler you will deploy the smart contract - escrow account which will be used for purpose to allow any executor perform withdrawal from your escrow account to the destination account when the time comes. Executors are incentivized to execute your scheduled task with vision of receiving small fee paid in ASA.Gold token - 0.001 gram of gold."])},
    "new_scheduled_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New scheduled payment"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay to"])},
    "send_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset to send"])},
    "create_escrow_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create escrow account"])},
    "list_of_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of active payments"])},
    "no_payment_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No scheduled payments has been found"])},
    "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow app id"])},
    "app_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow app address"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "fee_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee balance"])},
    "execution_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution fee"])},
    "description_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this detail overview of the scheduled payment you can see the current asset balance of the escrow account and you have actions to deposit more assets to the escrow account or to executors."])},
    "details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payment details"])},
    "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])},
    "deposit_fee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit asa.gold token to fee pool smart contract"])},
    "fee_asset_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution fee token"])},
    "amount_to_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to deposit to fees"])},
    "get_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get more tokenized gold"])},
    "list_of_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of assets at escrow account"])},
    "assets_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset information is loading"])},
    "asset_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset ID"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset name"])},
    "asset_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset amount"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
    "optin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow account optin action"])},
    "optin_to_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to asset"])},
    "optin_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in"])},
    "withdraw_asset_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw from escrow account"])},
    "withdraw_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset to withdraw"])},
    "withdraw_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to withdraw"])},
    "withdraw_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute withdrawal"])},
    "load_script_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load app execution profile"])},
    "load_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load"])},
    "xgov_promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This work has been performed with support from the Algorand Foundation xGov Grants Program"])}
  }
}