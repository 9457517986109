<template>
  <div class="grid">
    <div class="col-12">
      <h1>Merge multiple accounts to one</h1>
    </div>
  </div>
</template>

<script>
export default {};
</script>
