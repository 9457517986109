import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import PublicLayout from "../../layouts/Public.vue";
import NewWallet from "../../components/NewWallet.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'NewWallet',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PublicLayout, null, {
    default: _withCtx(() => [
      _createVNode(NewWallet)
    ]),
    _: 1
  }))
}
}

})