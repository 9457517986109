import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex align-items-center"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "flex align-items-center"
}

import { computed, ref } from "vue";
import { useStore } from "vuex";
import Dropdown from "primevue/dropdown";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectAccount',
  props: {
  modelValue: String,
  itemId: String,
  class: String,
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const model = computed({
  get() {
    return props.modelValue;
  },

  set(value) {
    return emit("update:modelValue", value);
  },
});

const store = useStore();
const { t } = useI18n();

function getAccountName() {
  const ret = store.state.wallet.privateAccounts.find(
    (a: any) => a.addr == props.modelValue
  );
  if (!ret) return props.modelValue;
  return `${ret.name}: ${props.modelValue}`;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dropdown), {
    modelValue: model.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
    filter: "",
    options: _unref(store).state.wallet.privateAccounts,
    optionLabel: "name",
    optionValue: "addr",
    placeholder: _ctx.$t('account.select_account'),
    class: _normalizeClass(props.class),
    inputClass: props.class,
    itemid: props.itemId
  }, {
    value: _withCtx((slotProps) => [
      (slotProps.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", null, _toDisplayString(getAccountName()), 1)
          ]))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(slotProps.placeholder), 1))
    ]),
    option: _withCtx((slotProps) => [
      (slotProps.option)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", null, _toDisplayString(slotProps.option.name) + " : " + _toDisplayString(slotProps.option.addr), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "options", "placeholder", "class", "inputClass", "itemid"]))
}
}

})