import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, unref as _unref, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field grid" }
const _hoisted_2 = {
  for: "arc200id",
  class: "col-12 mb-2 md:col-2 md:mb-0"
}
const _hoisted_3 = { class: "col-12 md:col-10" }
const _hoisted_4 = { class: "field grid" }
const _hoisted_5 = { class: "col-12 md:col-10" }
const _hoisted_6 = {
  key: 0,
  class: "field grid my-5"
}
const _hoisted_7 = { class: "col-12 mb-2 md:col-2 md:mb-0" }
const _hoisted_8 = { class: "col-12 md:col-10" }
const _hoisted_9 = {
  key: 1,
  class: "field grid mt-5"
}
const _hoisted_10 = { class: "col-12 mb-2 md:col-2 md:mb-0" }
const _hoisted_11 = { class: "col-12 md:col-10" }
const _hoisted_12 = {
  key: 2,
  class: "field grid"
}
const _hoisted_13 = { class: "col-12 mb-2 md:col-2 md:mb-0" }
const _hoisted_14 = { class: "col-12 md:col-10" }
const _hoisted_15 = {
  key: 3,
  class: "field grid"
}
const _hoisted_16 = { class: "col-12 mb-2 md:col-2 md:mb-0" }
const _hoisted_17 = { class: "col-12 md:col-10" }
const _hoisted_18 = {
  key: 4,
  class: "field grid"
}
const _hoisted_19 = { class: "col-12 mb-2 md:col-2 md:mb-0" }
const _hoisted_20 = { class: "col-12 md:col-10" }
const _hoisted_21 = {
  key: 5,
  class: "field grid"
}
const _hoisted_22 = { class: "col-12 mb-2 md:col-2 md:mb-0" }
const _hoisted_23 = { class: "col-12 md:col-10" }
const _hoisted_24 = {
  key: 6,
  class: "field grid"
}
const _hoisted_25 = { class: "col-12 mb-2 md:col-2 md:mb-0" }
const _hoisted_26 = { class: "col-12 md:col-10" }
const _hoisted_27 = {
  key: 7,
  class: "field grid"
}
const _hoisted_28 = { class: "col-12 mb-2 md:col-2 md:mb-0" }
const _hoisted_29 = { class: "col-12 md:col-10" }
const _hoisted_30 = {
  key: 8,
  class: "field grid"
}
const _hoisted_31 = { class: "col-12 md:col-10" }

import { onMounted, reactive } from "vue";
import MainLayout from "../../layouts/Main.vue";
import algosdk from "algosdk";
import Contract from "arc200js";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { getArc200Client } from "arc200-client";
import formatCurrencyBigInt from "../../scripts/numbers/formatCurrencyBigInt";

export default /*@__PURE__*/_defineComponent({
  __name: 'OptIn',
  setup(__props) {

const store = useStore();
const route = useRoute();
const router = useRouter();

const state = reactive({
  account: {
    name: "",
    addr: route.params.account as string,
  },
  arc200id: "",
  arc200Info: {
    arc200id: 0,
    name: "",
    symbol: "",
    decimals: BigInt(0),
    totalSupply: BigInt(0),
    balance: BigInt(0),
  },
  boxNotFound: false,
  loading: false,
});

onMounted(async () => {
  await store.dispatch("wallet/prolong");
});

const fetchAsset = async () => {
  try {
    const algodClient: algosdk.Algodv2 = await store.dispatch("algod/getAlgod");
    const indexerClient = await store.dispatch("indexer/getIndexer");
    state.arc200Info.arc200id = parseInt(state.arc200id);

    const contract = new Contract(
      state.arc200Info.arc200id,
      algodClient,
      indexerClient
    );
    state.loading = true;
    state.arc200Info = {
      arc200id: 0,
      name: "",
      symbol: "",
      decimals: BigInt(0),
      totalSupply: BigInt(0),
      balance: BigInt(0),
    };
    var name = await contract.arc200_name();
    await delay(200);
    if (!name.success) {
      store.dispatch(
        "toast/openError",
        `Failed to fetch ARC200 name at network ${store.state.config.envName}. You have problem with internet, or asset does not exist, or you are using wrong network.`
      );
      state.loading = false;
      return;
    }
    state.arc200Info.name = name.returnValue;
    var symbol = await contract.arc200_symbol();
    await delay(200);
    if (!symbol.success) {
      store.dispatch("toast/openError", "Failed to fetch ARC200 symbol");
      state.loading = false;
      return;
    }
    state.arc200Info.symbol = symbol.returnValue;
    var decimals = await contract.arc200_decimals();
    await delay(200);
    if (!decimals.success) {
      store.dispatch("toast/openError", "Failed to fetch ARC200 decimals");
      state.loading = false;
      return;
    }
    state.arc200Info.decimals = decimals.returnValue;

    var totalSupply = await contract.arc200_totalSupply();
    await delay(200);
    if (!totalSupply.success) {
      store.dispatch("toast/openError", "Failed to fetch ARC200 totalSupply");
      state.loading = false;
      return;
    }
    state.arc200Info.totalSupply = totalSupply.returnValue;

    var balance = await contract.arc200_balanceOf(state.account.addr);
    await delay(200);
    if (!balance.success) {
      console.error("balance request was not successful");
      store.dispatch("toast/openError", "Failed to fetch ARC200 balance");
      state.loading = false;
      return;
    }
    state.arc200Info.balance = balance.returnValue;
    state.arc200Info.arc200id = Number(state.arc200id);
    state.boxNotFound = !(await accountIsOptedInToArc200Asset(
      state.account.addr
    ));

    state.loading = false;
  } catch (err: any) {
    const error = err.message ?? err;
    console.error("failed to fetch arc200", error, err);
    await store.dispatch("toast/openError", error);
  }
};
const accountIsOptedInToArc200Asset = async (addr: string) => {
  const indexerClient = await store.dispatch("indexer/getIndexer");
  const fromDecoded = algosdk.decodeAddress(addr);
  const boxName = new Uint8Array(
    Buffer.concat([Buffer.from([0x00]), Buffer.from(fromDecoded.publicKey)])
  );
  try {
    const box = await indexerClient
      .lookupApplicationBoxByIDandName(state.arc200Info.arc200id, boxName)
      .do();
    return true;
  } catch (exc: any) {
    if (exc.message?.indexOf("no application boxes found")) {
      return false;
    } else {
      console.error(exc);
      throw exc;
    }
  }
};
const save = async () => {
  try {
    await store.dispatch("wallet/addArc200Asset", {
      addr: state.account.addr,
      arc200Info: state.arc200Info,
    });
    router.push({ name: "Accounts" });
  } catch (err: any) {
    const error = err.message ?? err;
    console.error("failed to addArc200Asset", error, err);
    await store.dispatch("toast/openError", error);
  }
};

const makeOptInTxs = async () => {
  const algod = await store.dispatch("algod/getAlgod");
  const appId = Number(state.arc200Info.arc200id);
  const client = getArc200Client({
    algod,
    appId: appId,
    sender: {
      addr: state.account.addr,
      signer: async () => {
        return [] as Uint8Array[];
      },
    },
  });
  const fromDecoded = algosdk.decodeAddress(state.account.addr);
  var boxFromDirect = {
    // : algosdk.BoxReference
    appIndex: appId,
    name: new Uint8Array(Buffer.from(fromDecoded.publicKey)),
  };
  var boxFrom = {
    // : algosdk.BoxReference
    appIndex: appId,
    name: new Uint8Array(
      Buffer.concat([Buffer.from([0x00]), Buffer.from(fromDecoded.publicKey)])
    ), // data box
  };
  var boxFromAddrText = {
    // : algosdk.BoxReference
    appIndex: appId,
    name: new Uint8Array(Buffer.from(state.account.addr, "ascii")), // box as the address encoded as text
  };
  console.log("boxes: [boxFromDirect, boxFrom, boxFromAddrText]", {
    boxes: [boxFromDirect, boxFrom, boxFromAddrText],
  });
  const compose = client.compose().arc200Transfer(
    { to: state.account.addr, value: BigInt(0) },
    {
      boxes: [boxFromDirect, boxFrom, boxFromAddrText],
    }
  );
  const enc = new TextEncoder();
  let noteEnc = enc.encode("o");
  const payTx = await store.dispatch("algod/preparePayment", {
    payTo: algosdk.getApplicationAddress(appId),
    payFrom: state.account.addr,
    amount: 28500,
    noteEnc: noteEnc,
    fee: undefined,
    asset: undefined,
    reKeyTo: undefined,
  });
  const atc = await compose.atc();

  const txsToSignArc200 = atc.buildGroup().map((tx) => tx.txn);
  const txsToSign = [];
  txsToSign.push(payTx);
  txsToSignArc200.forEach((tx) => {
    txsToSign.push(tx);
  });
  algosdk.assignGroupID(txsToSign);
  return txsToSign;
};

const createBoxClick = async () => {
  try {
    await store.dispatch("signer/toSignArray", {
      txs: await makeOptInTxs(),
    });
    await router.push("/signAll");
  } catch (err: any) {
    const error = err.message ?? err;
    console.error("failed to addArc200Asset", error, err);
    await store.dispatch("toast/openError", error);
  }
};

const delay = (ms: any) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!

  return state
    ? (_openBlock(), _createBlock(MainLayout, { key: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t("arc200.optin_header")) + " " + _toDisplayString(state?.account?.name), 1),
          _createVNode(_component_Card, null, {
            content: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("acc_overview.asset_optinArc200_help")), 1),
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("arc200.app_id")), 1),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_InputText, {
                    id: "arc200id",
                    modelValue: state.arc200id,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.arc200id) = $event)),
                    class: "w-full"
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _cache[6] || (_cache[6] = _createElementVNode("label", { class: "col-12 mb-2 md:col-2 md:mb-0" }, null, -1)),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_Button, {
                    class: "my-2 mr-2",
                    onClick: fetchAsset,
                    severity: state.arc200Info.name ? 'secondary' : 'primary',
                    disabled: state.loading || !state.arc200id
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("arc200.fetch_action")) + " ", 1),
                      (state.loading)
                        ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                            key: 0,
                            class: "ml-2",
                            style: {"width":"1em","height":"1em"},
                            strokeWidth: "10"
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["severity", "disabled"]),
                  _createVNode(_component_Button, {
                    class: "my-2",
                    disabled: !state.arc200id,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => {
                state.arc200id = '';
                state.arc200Info.name = '';
                state.loading = false;
              }),
                    severity: "secondary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("arc200.reset")), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ])
              ]),
              (
            !state.arc200Info.name &&
            !state.loading &&
            _unref(store).state.config.env == 'voitest-v1'
          )
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("arc200.examples")), 1),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_Button, {
                        class: "mr-1",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (state.arc200id = '6779767')),
                        severity: "secondary"
                      }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode(" Via ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_Button, {
                        class: "mr-1",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (state.arc200id = '6795477')),
                        severity: "secondary"
                      }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode(" TacoCoin ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_Button, {
                        class: "mr-1",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (state.arc200id = '23214349')),
                        severity: "secondary"
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode(" NFT Rewards ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_Button, {
                        class: "mr-1",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (state.arc200id = '24590664')),
                        severity: "secondary"
                      }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode(" Wrapped Voi ")
                        ])),
                        _: 1
                      })
                    ])
                  ]))
                : _createCommentVNode("", true),
              (state.arc200Info.arc200id && !state.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("arc200.app_id")), 1),
                    _createElementVNode("div", _hoisted_11, _toDisplayString(state.arc200Info.arc200id), 1)
                  ]))
                : _createCommentVNode("", true),
              (state.arc200Info.arc200id && !state.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("arc200.name")), 1),
                    _createElementVNode("div", _hoisted_14, _toDisplayString(state.arc200Info.name), 1)
                  ]))
                : _createCommentVNode("", true),
              (state.arc200Info.arc200id && !state.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("arc200.symbol")), 1),
                    _createElementVNode("div", _hoisted_17, _toDisplayString(state.arc200Info.symbol), 1)
                  ]))
                : _createCommentVNode("", true),
              (state.arc200Info.arc200id && !state.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t("arc200.decimals")), 1),
                    _createElementVNode("div", _hoisted_20, _toDisplayString(state.arc200Info.decimals), 1)
                  ]))
                : _createCommentVNode("", true),
              (state.arc200Info.arc200id && !state.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t("arc200.supply")), 1),
                    _createElementVNode("div", _hoisted_23, _toDisplayString(_unref(formatCurrencyBigInt)(
                state.arc200Info.totalSupply,
                state.arc200Info.symbol,
                Number(state.arc200Info.decimals)
              )), 1)
                  ]))
                : _createCommentVNode("", true),
              (state.arc200Info.arc200id && !state.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t("arc200.balance")), 1),
                    _createElementVNode("div", _hoisted_26, _toDisplayString(_unref(formatCurrencyBigInt)(
                state.arc200Info.balance,
                state.arc200Info.symbol,
                Number(state.arc200Info.decimals)
              )), 1)
                  ]))
                : _createCommentVNode("", true),
              (state.arc200Info.arc200id && !state.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                    _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t("arc200.is_opted_in")), 1),
                    _createElementVNode("div", _hoisted_29, _toDisplayString(!state.boxNotFound) + " " + _toDisplayString(state.boxNotFound ? "- Box does not exists yet" : "- Box exists"), 1)
                  ]))
                : _createCommentVNode("", true),
              (state.arc200Info.arc200id && !state.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                    _cache[11] || (_cache[11] = _createElementVNode("label", { class: "col-12 mb-2 md:col-2 md:mb-0" }, null, -1)),
                    _createElementVNode("div", _hoisted_31, [
                      (state.boxNotFound)
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 0,
                            class: "my-2 mr-2",
                            onClick: createBoxClick,
                            severity: "primary"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("arc200.create_box")), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_Button, {
                        class: "my-2",
                        onClick: save,
                        disabled: state.boxNotFound,
                        severity: state.boxNotFound ? 'secondary' : 'primary'
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("arc200.save_action")), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled", "severity"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})