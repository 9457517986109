import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, unref as _unref } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "field grid" }
const _hoisted_3 = {
  class: "col-12 mb-2 md:col-2 md:mb-0",
  for: "email"
}
const _hoisted_4 = { class: "col-12 md:col-10" }
const _hoisted_5 = { class: "field grid" }
const _hoisted_6 = {
  for: "w",
  class: "col-12 mb-2 md:col-2 md:mb-0"
}
const _hoisted_7 = { class: "col-12 md:col-10" }
const _hoisted_8 = { class: "field grid" }
const _hoisted_9 = {
  class: "col-12 mb-2 md:col-2 md:mb-0",
  for: "savePassword"
}
const _hoisted_10 = { class: "col-12 md:col-10" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "field grid" }
const _hoisted_13 = {
  class: "col-12 mb-2 md:col-2 md:mb-0",
  for: "name"
}
const _hoisted_14 = { class: "col-12 md:col-10" }
const _hoisted_15 = { class: "field grid" }
const _hoisted_16 = { class: "col-12 md:col-10" }

import MainLayout from "../../layouts/Main.vue";
import { computed, onMounted, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import Password from "primevue/password";
import InputText from "primevue/inputtext";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import InputSwitch from "primevue/inputswitch";
import { passwordStrength } from "check-password-strength";


export default /*@__PURE__*/_defineComponent({
  __name: 'EmailPassword',
  setup(__props) {

const state = reactive({
  lastError: "",
  email: "",
  password: "",
  name: "",
  savePassword: true,
  emailIsValid: false,
});

const canCreatePassword = computed(() => {
  return state.emailIsValid && !!state.name && state.password.length >= 16;
});
const { t } = useI18n(); // use as global scope

const store = useStore();
const router = useRouter();

function checkEmailValidity() {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  state.emailIsValid = emailRegex.test(state.email);
}

async function createAccount() {
  try {
    await store.dispatch("wallet/addEmailPasswordAccount", {
      name: state.name,
      savePassword: state.savePassword,
      email: state.email,
      password: state.password,
    });

    router.push("/account/" + store.state.wallet.lastActiveAccount);
  } catch (err: any) {
    const error = err.message ?? err;
    console.error("failed to create account", error, err);
    await store.dispatch("toast/openError", error);
  }
}
onMounted(async () => {
  await store.dispatch("wallet/prolong");
});

return (_ctx: any,_cache: any) => {
  const _component_Message = _resolveComponent("Message")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(MainLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t("arc76account.title")), 1),
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          (state.lastError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_Message, { severity: "error" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("new_account_pass.last_error")) + ": " + _toDisplayString(state.lastError), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("arc76account.description")), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("arc76account.description2")), 1),
          (!state.savePassword)
            ? (_openBlock(), _createBlock(_component_Message, {
                key: 1,
                severity: "error"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("arc76account.arc_draft")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("arc76account.email")), 1),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_unref(InputText), {
                class: "w-full",
                id: "email",
                modelValue: state.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.email) = $event)),
                onKeyup: checkEmailValidity
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("arc76account.select_password")), 1),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_unref(Password), {
                class: "w-full",
                inputClass: "w-full",
                "input-props": { autocomplete: 'new-password' },
                inputId: "w",
                modelValue: state.password,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((state.password) = $event)),
                feedback: false,
                "toggle-mask": true
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("arc76account.save_password_switch")), 1),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_unref(InputSwitch), {
                class: "my-2",
                modelValue: state.savePassword,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((state.savePassword) = $event)),
                inputId: "savePassword"
              }, null, 8, ["modelValue"]),
              (!state.savePassword)
                ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.$t("arc76account.password_not_stored")), 1))
                : _createCommentVNode("", true),
              (!state.savePassword)
                ? (_openBlock(), _createBlock(_component_Message, {
                    key: 1,
                    severity: "error"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("arc76account.gui_not_implemented")), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("accounts.account_name")), 1),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_unref(InputText), {
                id: "name",
                modelValue: state.name,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((state.name) = $event)),
                class: "w-full"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _cache[4] || (_cache[4] = _createElementVNode("label", { class: "col-12 mb-2 md:col-2 md:mb-0" }, null, -1)),
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_Button, {
                class: "my-2",
                onClick: createAccount,
                disabled: !canCreatePassword.value,
                id: "create_account"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("newacc.create_account")), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})