import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "text-end"
}
const _hoisted_3 = {
  key: 1,
  class: "text-end"
}
const _hoisted_4 = { class: "p-3" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { key: 4 }
const _hoisted_10 = { key: 5 }
const _hoisted_11 = { key: 6 }
const _hoisted_12 = { key: 7 }

import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { onMounted, reactive } from "vue";
import MainLayout from "../layouts/Main.vue";
import algosdk from "algosdk";
import formatCurrency from "../scripts/numbers/formatCurrency";


export default /*@__PURE__*/_defineComponent({
  __name: 'SignAll',
  setup(__props) {

const store = useStore();
const router = useRouter();

const state = reactive({
  expandedTransactions: [],
  selectedTransaction: {},
  transactions: [],
  atLeastOneSigned: false,
  allTxsAreSigned: false,
  processing: false,
  error: "",
  confirmedRound: 0,
});

onMounted(async () => {
  await store.dispatch("wallet/prolong");

  const data = [];
  let index = 0;
  for (const txn of store.state.signer.toSignArray) {
    data.push({
      txn,
      index,
      type: txn.type,
      from: algosdk.encodeAddress(txn.from.publicKey),
      toBeSigned: await toBeSigned({ txn }),
    });
    index++;
  }
  state.transactions = data;
  await checkAtLeastOneSigned();
  await checkAllTxsAreSigned();
});

const formatGenesisHash = (genesisHash) => {
  return Buffer.from(genesisHash).toString("base64");
};

const encodeAddress = (addr) => {
  if (!addr || !addr.publicKey) return "-";
  return algosdk.encodeAddress(addr.publicKey);
};
const toBeSigned = async (data) => {
  const txId = data.txn.txID();
  const signed = txId in store.state.signer.signed;
  if (!signed) return true; // if not signed return true to show sign button
  const from = encodeAddress(data.txn.from);
  const type = await store.dispatch("signer/getSignerType", {
    from: from,
  });
  if (type == "msig") {
    // check sign threshold
    const signedTx = algosdk.decodeSignedTransaction(
      store.state.signer.signed[txId]
    );

    const ret =
      signedTx.msig.subsig.filter((s) => !!s.s).length < signedTx.msig.thr;
    return ret;
  } else {
    return !signed;
  }
};
const formatAppAccount = (acc) => {
  try {
    return algosdk.encodeAddress(acc.publicKey);
  } catch {
    return acc;
  }
};

const clickSign = async (data) => {
  const txId = data.txn.txID();
  const isSigned = txId in store.state.signer.signed;
  if (isSigned) {
    for (const index in state.transactions) {
      if (state.transactions[item].txn.txID() == txId) {
        state.transactions[item].toBeSigned = await toBeSigned({ txn });
      }
    }
    await checkAtLeastOneSigned();
    await checkAllTxsAreSigned();
    return;
  }
  const type = await store.dispatch("signer/getSignerType", {
    from: data.from,
  });
  if (type == "msig") {
    await store.dispatch("signer/returnTo", "SignAll");
    await store.dispatch("signer/toSign", { tx: data.txn });
    const encoded = base642base64url(
      Buffer.from(algosdk.encodeUnsignedTransaction(data.txn)).toString(
        "base64"
      )
    );
    router.push(`/sign/${data.from}/${encoded}`);
  } else {
    const signed = await store.dispatch("signer/signTransaction", {
      from: data.from,
      signator: data.from,
      tx: data.txn,
    });
    for (const index in state.transactions) {
      if (state.transactions[index].txn.txID() == txId) {
        state.transactions[index].toBeSigned = await toBeSigned({
          txn: data.txn,
        });
      }
    }
  }
  await checkAtLeastOneSigned();
  await checkAllTxsAreSigned();
};
const base642base64url = (input) => {
  return input.replaceAll("+", "-").replaceAll("/", "_").replaceAll("=", "");
};
const isASCIIText = (str) => {
  return /^[\x20-\x7E]*$/.test(str);
};
const formatData = (arg, type) => {
  try {
    if (Buffer.from(arg).length == 0) return "";
    if (type == "Text") {
      if (!isASCIIText(Buffer.from(arg).toString("utf-8")))
        return "-- Non ASCII --";
      return `${Buffer.from(arg).toString("utf-8")}`;
    }
    if (type == "UInt") {
      if (Buffer.from(arg).length != 8) return "";
      return `Num: ${algosdk.decodeUint64(new Uint8Array(Buffer.from(arg)))}`;
    }
    if (type == "Hex") return `Hex: 0x${Buffer.from(arg).toString("hex")}`;
    if (type == "B64") return `B64: ${Buffer.from(arg).toString("base64")}`;
    return arg;
  } catch {
    return arg;
  }
};

const formatGroup = (group) => {
  try {
    return group.toString("base64");
  } catch {
    return group;
  }
};
const clickSignAll = async () => {
  for (const tx of state.transactions) {
    await clickSign(tx);
  }
};
const checkAtLeastOneSigned = () => {
  for (let tx of state.transactions) {
    if (tx.txn.txID() in store.state.signer.signed) {
      state.atLeastOneSigned = true;
      return true;
    }
  }
  state.atLeastOneSigned = false;
  return false;
};
const checkAllTxsAreSigned = () => {
  let result = true;
  for (let tx of state.transactions) {
    const id = tx.txn.txID();
    if (!(id in store.state.signer.signed)) {
      result = false;
    }
  }
  state.allTxsAreSigned = result;
  return result;
};

const submitSignedClick = async () => {
  try {
    state.processing = true;
    await store.dispatch("wallet/prolong");
    const signed = [];
    for (const txTableObject of state.transactions) {
      const id = txTableObject.txn.txID();
      if (!(id in store.state.signer.signed)) {
        throw Error(`Transaction ${id} not signed`);
      }
      signed.push(store.state.signer.signed[id]);
    }
    const tx = (
      await store.dispatch("algod/sendRawTransaction", {
        signedTxn: signed,
      })
    )?.txId;

    if (!tx) {
      console.error("submitSignedClick has failed");
      state.error = $t("pay.state_error_not_sent");
      store.dispatch("toast/openError", state.error);
      state.processing = false;
    }

    const confirmation = await store.dispatch("algod/waitForConfirmation", {
      txId: tx,
      timeout: 4,
    });
    if (!confirmation) {
      console.error(`confirmation not received for tx`);
      state.processing = false;
      state.error = $t("pay.state_error_not_sent");
      store.dispatch("toast/openError", state.error);
      //            "Payment has probably not reached the network. Are you offline? Please check you account";
      return;
    }
    if (confirmation["confirmed-round"]) {
      state.processing = false;
      state.confirmedRound = confirmation["confirmed-round"];
      store.dispatch(
        "toast/openSuccess",
        `Transaction confirmed in round ${state.confirmedRound}`
      );
    }
    if (confirmation["pool-error"]) {
      state.processing = false;
      state.error = confirmation["pool-error"];
    }
    state.processing = false;
  } catch (exc) {
    console.error("submitSignedClick.error", exc);
    state.error = exc.message ?? exc;
    store.dispatch("toast/openError", state.error);
  }
};
const retToScheduledPayments = () => {
  router.push({
    name: "scheduled-payment",
    params: { account: store.state.wallet.lastActiveAccount },
  });
};

const getAssetSync = (id) => {
  const ret = store.state.indexer.assets.find((a) => a["asset-id"] == id);
  return ret;
};
const getAssetName = (id) => {
  const asset = getAssetSync(id);
  return asset.name;
};
const getAssetDecimals = (id) => {
  const asset = getAssetSync(id);
  return asset["decimals"];
};

return (_ctx: any,_cache: any) => {
  const _component_Message = _resolveComponent("Message")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(MainLayout, null, {
    default: _withCtx(() => [
      _cache[5] || (_cache[5] = _createElementVNode("h1", null, "Sign multiple transactions", -1)),
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          (state.error)
            ? (_openBlock(), _createBlock(_component_Message, {
                key: 0,
                severity: "error"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(state.error), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (state.confirmedRound)
            ? (_openBlock(), _createBlock(_component_Message, {
                key: 1,
                severity: "success"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(`Transaction confirmed in round ${state.confirmedRound}`), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _createVNode(_component_Button, {
              class: "mr-1",
              disabled: state.allTxsAreSigned || state.confirmedRound,
              severity: 
              state.allTxsAreSigned || state.confirmedRound
                ? 'secondary'
                : 'primary'
            ,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (clickSignAll()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("connect.sign_all")), 1)
              ]),
              _: 1
            }, 8, ["disabled", "severity"]),
            _createVNode(_component_Button, {
              disabled: 
              !state.allTxsAreSigned || state.processing || state.confirmedRound
            ,
              severity: 
              !state.allTxsAreSigned || state.processing || state.confirmedRound
                ? 'secondary'
                : 'primary'
            ,
              onClick: submitSignedClick
            }, {
              default: _withCtx(() => [
                _cache[3] || (_cache[3] = _createTextVNode(" Send tx to the network ")),
                (state.processing)
                  ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                      key: 0,
                      style: {"width":"1em","height":"1em"},
                      strokeWidth: "10",
                      class: "ml-2"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["disabled", "severity"]),
            (_ctx.$store.state.signer.returnToSignAll == 'ScheduledPayments')
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  class: "ml-2",
                  severity: state.confirmedRound ? 'primary' : 'secondary',
                  disabled: !state.atLeastOneSigned,
                  onClick: retToScheduledPayments
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" Return to scheduled payment management ")
                  ])),
                  _: 1
                }, 8, ["severity", "disabled"]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_DataTable, {
            expandedRows: state.expandedTransactions,
            "onUpdate:expandedRows": _cache[1] || (_cache[1] = ($event: any) => ((state.expandedTransactions) = $event)),
            selection: state.selectedTransaction,
            "onUpdate:selection": _cache[2] || (_cache[2] = ($event: any) => ((state.selectedTransaction) = $event)),
            value: state.transactions,
            "selection-mode": "single"
          }, {
            expansion: _withCtx((txProps) => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("table", null, [
                  (txProps.data.txn.from)
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_5, [
                        _createElementVNode("td", null, _toDisplayString(_ctx.$t("connect.from")) + ":", 1),
                        _createElementVNode("td", null, _toDisplayString(encodeAddress(txProps.data.txn.from)), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (txProps.data.txn.to)
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_6, [
                        _createElementVNode("td", null, _toDisplayString(_ctx.$t("connect.to")) + ":", 1),
                        _createElementVNode("td", null, _toDisplayString(encodeAddress(txProps.data.txn.to)), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.$t("connect.validity")) + ":", 1),
                    _createElementVNode("td", null, _toDisplayString(txProps.data.txn.firstRound) + " - " + _toDisplayString(txProps.data.txn.lastRound) + " (" + _toDisplayString(txProps.data.txn.lastRound -
                      txProps.data.txn.firstRound +
                      1) + " " + _toDisplayString(_ctx.$t("connect.rounds")) + ") ", 1)
                  ]),
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.$t("connect.type")) + ":", 1),
                    _createElementVNode("td", null, _toDisplayString(txProps.data.type), 1)
                  ]),
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.$t("connect.note")) + ":", 1),
                    _createElementVNode("td", null, [
                      _createElementVNode("table", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", null, _toDisplayString(formatData(txProps.data.txn.note, "Text")), 1),
                          _createElementVNode("td", null, _toDisplayString(formatData(txProps.data.txn.note, "UInt")), 1),
                          _createElementVNode("td", null, _toDisplayString(formatData(txProps.data.txn.note, "Hex")), 1),
                          _createElementVNode("td", null, _toDisplayString(formatData(txProps.data.txn.note, "B64")), 1)
                        ])
                      ])
                    ])
                  ]),
                  (txProps.data.txn.group)
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_7, [
                        _createElementVNode("td", null, _toDisplayString(_ctx.$t("connect.group")) + ":", 1),
                        _createElementVNode("td", null, _toDisplayString(formatGroup(txProps.data.txn.group)), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (txProps.data.type == 'appl')
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_8, [
                        _createElementVNode("td", null, _toDisplayString(_ctx.$t("connect.app")) + ":", 1),
                        _createElementVNode("td", null, _toDisplayString(txProps.data.txn.appIndex), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (txProps.data.type == 'appl' && txProps.data.txn.appArgs)
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_9, [
                        _createElementVNode("td", null, _toDisplayString(_ctx.$t("connect.app_args")) + ":", 1),
                        _createElementVNode("td", null, [
                          _createElementVNode("table", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(txProps.data.txn.appArgs, (arg, index) => {
                              return (_openBlock(), _createElementBlock("tr", { key: arg }, [
                                _createElementVNode("td", null, _toDisplayString(index + 1) + ".", 1),
                                _createElementVNode("td", null, _toDisplayString(formatData(arg, "Text")), 1),
                                _createElementVNode("td", null, _toDisplayString(formatData(arg, "UInt")), 1),
                                _createElementVNode("td", null, _toDisplayString(formatData(arg, "Hex")), 1),
                                _createElementVNode("td", null, _toDisplayString(formatData(arg, "B64")), 1)
                              ]))
                            }), 128))
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (
                    txProps.data.type == 'appl' && txProps.data.txn.appAccounts
                  )
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_10, [
                        _createElementVNode("td", null, _toDisplayString(_ctx.$t("connect.app_accounts")) + ":", 1),
                        _createElementVNode("td", null, [
                          _createElementVNode("ol", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(txProps.data.txn.appAccounts, (acc) => {
                              return (_openBlock(), _createElementBlock("li", { key: acc }, _toDisplayString(formatAppAccount(acc)), 1))
                            }), 128))
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (
                    txProps.data.type == 'appl' &&
                    txProps.data.txn.appForeignAssets
                  )
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_11, [
                        _createElementVNode("td", null, _toDisplayString(_ctx.$t("connect.app_assets")) + ":", 1),
                        _createElementVNode("td", null, [
                          _createElementVNode("ol", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(txProps.data.txn.appForeignAssets, (asset) => {
                              return (_openBlock(), _createElementBlock("li", { key: asset }, _toDisplayString(asset), 1))
                            }), 128))
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (txProps.data.type == 'appl' && txProps.data.txn.boxes)
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_12, [
                        _createElementVNode("td", null, _toDisplayString(_ctx.$t("connect.boxes")) + ":", 1),
                        _createElementVNode("td", null, [
                          _createElementVNode("ol", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(txProps.data.txn.boxes, (box) => {
                              return (_openBlock(), _createElementBlock("li", {
                                key: box.name
                              }, _toDisplayString(_ctx.$t("connect.app")) + ": " + _toDisplayString(box.appIndex) + ", " + _toDisplayString(_ctx.$t("connect.name")) + ": " + _toDisplayString(box.name), 1))
                            }), 128))
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.$t("connect.genesis")) + ":", 1),
                    _createElementVNode("td", null, _toDisplayString(txProps.data.txn.genesisID), 1)
                  ]),
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.$t("connect.genesis_hash")) + ":", 1),
                    _createElementVNode("td", null, _toDisplayString(formatGenesisHash(txProps.data.txn.genesisHash)), 1)
                  ])
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                expander: "",
                style: {"width":"5rem"}
              }),
              _createVNode(_component_Column, null, {
                body: _withCtx((slotProps) => [
                  (slotProps.data.toBeSigned)
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        class: "m-1",
                        onClick: ($event: any) => (clickSign(slotProps.data))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("connect.sign")), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : (_openBlock(), _createBlock(_component_Badge, {
                        key: 1,
                        severity: "success",
                        class: "badge bg-success",
                        value: _ctx.$t('connect.signed')
                      }, null, 8, ["value"]))
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "index",
                header: _ctx.$t('connect.index'),
                sortable: true
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "type",
                header: _ctx.$t('connect.type'),
                sortable: true
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "from",
                header: _ctx.$t('connect.from'),
                sortable: true
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "asset",
                header: _ctx.$t('connect.asset'),
                sortable: true
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "amount",
                header: _ctx.$t('connect.amount'),
                sortable: true
              }, {
                body: _withCtx((slotProps) => [
                  (slotProps.data.txn)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        (slotProps.data.txn['type'] == 'pay')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(formatCurrency)(slotProps.data.txn["amount"])), 1))
                          : (slotProps.data.txn['type'] == 'axfer')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(formatCurrency)(
                      slotProps.data.txn["amount"],
                      getAssetName(slotProps.data.txn["assetIndex"]),
                      getAssetDecimals(slotProps.data.txn["assetIndex"])
                    )), 1))
                            : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "fee",
                header: _ctx.$t('connect.fee'),
                sortable: true
              }, {
                body: _withCtx((slotProps) => [
                  _createTextVNode(_toDisplayString(_unref(formatCurrency)(slotProps.data.txn["fee"])), 1)
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_Column, {
                field: "rekeyTo",
                header: _ctx.$t('connect.rekeyto'),
                sortable: true
              }, null, 8, ["header"])
            ]),
            _: 1
          }, 8, ["expandedRows", "selection", "value"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})