import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "field grid" }
const _hoisted_2 = {
  for: "address",
  class: "col-12 mb-2 md:col-2 md:mb-0"
}
const _hoisted_3 = { class: "col-12 md:col-10" }
const _hoisted_4 = { class: "field grid" }
const _hoisted_5 = {
  for: "name",
  class: "col-12 mb-2 md:col-2 md:mb-0"
}
const _hoisted_6 = { class: "col-12 md:col-10" }
const _hoisted_7 = { class: "field grid" }
const _hoisted_8 = { class: "col-12 md:col-10" }

import MainLayout from "../../layouts/Main.vue";
import { onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'Watch',
  setup(__props) {

const state = reactive({
  addr: "",
  name: "",
});

const reset = async () => {
  state.name = "";
  state.addr = "";

  await store.dispatch("wallet/prolong");
  router.push({ name: "Accounts" });
};

const store = useStore();
const router = useRouter();

async function watchAccountClick() {
  try {
    await store.dispatch("wallet/addPublicAccount", {
      name: state.name,
      addr: state.addr,
    });
    router.push({ name: "Accounts" });
  } catch (err: any) {
    const error = err.message ?? err;
    console.error("failed to create account", error, err);
    await store.dispatch("toast/openError", error);
  }
}
onMounted(async () => {
  await store.dispatch("wallet/prolong");
});

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(MainLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t("newacc.watch_account")), 1),
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("newacc.address")), 1),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_InputText, {
                id: "address",
                modelValue: state.addr,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.addr) = $event)),
                class: "w-full"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("newacc.name")), 1),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_InputText, {
                id: "name",
                modelValue: state.name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((state.name) = $event)),
                class: "w-full"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[2] || (_cache[2] = _createElementVNode("label", {
              for: "address",
              class: "col-12 mb-2 md:col-2 md:mb-0"
            }, null, -1)),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_Button, {
                severity: "primary",
                class: "my-1",
                onClick: watchAccountClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("newacc.watch_account")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Button, {
                severity: "secondary",
                class: "m-1",
                onClick: reset
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("global.go_back")), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})