import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Suspense as _Suspense, createBlock as _createBlock, unref as _unref } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "p-fluid" }
const _hoisted_3 = { class: "p-field mb-2" }
const _hoisted_4 = { for: "account1" }
const _hoisted_5 = { class: "p-field mb-2" }
const _hoisted_6 = { for: "account1" }
const _hoisted_7 = { class: "p-field mb-2" }
const _hoisted_8 = { for: "account2" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "p-field mb-2" }
const _hoisted_12 = { for: "name" }
const _hoisted_13 = { class: "p-field mb-2" }
const _hoisted_14 = { for: "txtCode" }

import { mapActions, useStore } from "vuex";
import MainLayout from "../../layouts/Main.vue";
import { reactive } from "vue";
import { useI18n } from "vue-i18n";
import InputMask from "primevue/inputmask";
import SelectAccount from "@/components/SelectAccount.vue";
import Select2FAServer from "@/components/Select2FAServer.vue";

import algosdk from "algosdk";
import { useRouter } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: '2FA',
  setup(__props) {

const state = reactive({
  lastError: "",
  account1: "",
  account2: "",
  auth2FAResp: {
    qrCodeSetupImageUrl: "",
    manualEntryKey: "",
  },
  txtCode: "",
  authToken: "",
  name: "",
  confirmResp: undefined,
  server: "",
});

const { t } = useI18n(); // use as global scope

const store = useStore();
const router = useRouter();

async function arc14Request() {
  try {
    const realm = await store.dispatch("fa2/getRealm", {
      twoFactorAuthProvider: state.server,
    });
    state.authToken = await store.dispatch("arc14/signAuthTx", {
      account: state.account1,
      realm,
    });
    if (!state.authToken) return;
    state.auth2FAResp = await store.dispatch("fa2/setupAuthenticator", {
      authToken: state.authToken,
      account: state.account1,
      secondaryAccount: state.account2,
      twoFactorAuthProvider: state.server,
    });
  } catch (err: any) {
    const error = err.message ?? err;
    console.error("failed to do arc14Request", error, err);
    await store.dispatch("toast/openError", error);
  }
}
async function confirmRequest() {
  try {
    if (!state.authToken) return;

    state.confirmResp = await store.dispatch("fa2/confirmAuthenticator", {
      authToken: state.authToken,
      secondaryAccount: state.account2,
      txtCode: state.txtCode,
      twoFactorAuthProvider: state.server,
    });
    if (state.confirmResp) {
      await store.dispatch("wallet/add2FAAccount", {
        name: state.name,
        primaryAccount: state.account1,
        recoveryAccount: state.account2,
        twoFactorAccount: state.confirmResp,
        twoFactorAuthProvider: state.server,
      });
      router.push({ name: "Accounts" });
    }
  } catch (err: any) {
    const error = err.message ?? err;
    console.error("failed to confirmRequest", error, err);
    await store.dispatch("toast/openError", error);
  }
}

return (_ctx: any,_cache: any) => {
  const _component_Message = _resolveComponent("Message")!
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(MainLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t("new_account_2fa.title")), 1),
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          (state.lastError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_Message, { severity: "error" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("general.last_error")) + ": " + _toDisplayString(state.lastError), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("new_account_2fa.help1")), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("new_account_2fa.help2")), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("new_account_2fa.help3")), 1),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("new_account_2fa.2fa_provider")), 1),
              (_openBlock(), _createBlock(_Suspense, null, {
                fallback: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("general.loading")), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(Select2FAServer, {
                    modelValue: state.server,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.server) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }))
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("new_account_2fa.hot_account")), 1),
              _createVNode(SelectAccount, {
                modelValue: state.account1,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((state.account1) = $event)),
                class: "w-full"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("new_account_2fa.cold_account")), 1),
              _createVNode(SelectAccount, {
                modelValue: state.account2,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((state.account2) = $event)),
                class: "w-full"
              }, null, 8, ["modelValue"])
            ]),
            _createVNode(_component_Button, {
              class: "my-2",
              onClick: arc14Request,
              disabled: 
              !state.account1 ||
              !state.account2 ||
              state.account1 == state.account2
            
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("new_account_2fa.request_qr_code")), 1)
              ]),
              _: 1
            }, 8, ["disabled"]),
            (state.auth2FAResp && state.auth2FAResp.qrCodeSetupImageUrl)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.$t("new_account_2fa.scan_qr")), 1),
                  _createElementVNode("img", {
                    src: state.auth2FAResp.qrCodeSetupImageUrl,
                    width: "200",
                    height: "200"
                  }, null, 8, _hoisted_10),
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t("new_account_2fa.manual_entry_key")), 1),
                  _createElementVNode("code", null, _toDisplayString(state.auth2FAResp.manualEntryKey), 1),
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t("new_account_2fa.confirm_2fa")), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t("new_account_2fa.confirm_help")), 1),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t("accounts.account_name")), 1),
                    _createVNode(_component_InputText, {
                      id: "name",
                      modelValue: state.name,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((state.name) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t("accounts.2fa_code")), 1),
                    _createVNode(_unref(InputMask), {
                      itemid: "txtCode",
                      modelValue: state.txtCode,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((state.txtCode) = $event)),
                      mask: "999-999"
                    }, null, 8, ["modelValue"])
                  ]),
                  _createVNode(_component_Button, {
                    class: "my-2",
                    onClick: confirmRequest
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("new_account_2fa.save_button")), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})