import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "field grid" }
const _hoisted_2 = { class: "col-12 md:col-10" }
const _hoisted_3 = { class: "field grid" }
const _hoisted_4 = { class: "col-12 md:col-10" }
const _hoisted_5 = { class: "field grid" }
const _hoisted_6 = { class: "col-12 md:col-10" }
const _hoisted_7 = { class: "field grid" }
const _hoisted_8 = { class: "col-12 md:col-10" }
const _hoisted_9 = { class: "field grid" }
const _hoisted_10 = { class: "col-12 md:col-10" }
const _hoisted_11 = { class: "field grid" }
const _hoisted_12 = { class: "col-12 md:col-10" }
const _hoisted_13 = { class: "field grid" }
const _hoisted_14 = { class: "col-12 md:col-10" }
const _hoisted_15 = { class: "field grid" }
const _hoisted_16 = { class: "col-12 md:col-10" }
const _hoisted_17 = { class: "field grid" }
const _hoisted_18 = { class: "col-12 md:col-10" }
const _hoisted_19 = { class: "field grid" }
const _hoisted_20 = { class: "col-12 md:col-10" }
const _hoisted_21 = { class: "field grid" }
const _hoisted_22 = { class: "col-12 md:col-10" }
const _hoisted_23 = { class: "field grid" }
const _hoisted_24 = { class: "col-12 md:col-10" }

import MainLayout from "../layouts/Main.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

export default /*@__PURE__*/_defineComponent({
  __name: 'NewAccount',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(MainLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t("newacc.title")), 1),
      _createVNode(_unref(TabView), null, {
        default: _withCtx(() => [
          _createVNode(_unref(TabPanel), {
            header: _ctx.$t('newacc.basic_options'),
            headerClass: "mr-2"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_RouterLink, {
                  to: "/new-account/ed25519",
                  class: "col-12 mb-2 md:col-2 md:mb-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Button, { class: "w-full" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("newacc.create_basic")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("newacc.create_basic_help")), 1)
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_RouterLink, {
                  to: "/new-account/import-ed25519",
                  class: "col-12 mb-2 md:col-2 md:mb-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Button, { class: "w-full" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("newacc.import_account")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("newacc.import_account_help")), 1)
              ])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_unref(TabPanel), {
            header: _ctx.$t('newacc.advanced_options'),
            headerClass: "mr-2"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_RouterLink, {
                  to: "/new-account/ed25519",
                  class: "col-12 mb-2 md:col-2 md:mb-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Button, { class: "w-full" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("newacc.create_basic")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("newacc.create_basic_help")), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_RouterLink, {
                  to: "/new-account/ledger",
                  class: "col-12 mb-2 md:col-2 md:mb-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Button, { class: "w-full" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("newacc.ledger_account")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("newacc.ledger_account_help")), 1)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_RouterLink, {
                  to: "/new-account/wc",
                  class: "col-12 mb-2 md:col-2 md:mb-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Button, { class: "w-full" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("newacc.wc_account")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("newacc.wc_account_help")), 1)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_RouterLink, {
                  to: "/new-account/2fa",
                  class: "col-12 mb-2 md:col-2 md:mb-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Button, { class: "w-full" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("newacc.twofa_account")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("newacc.twofa_account_help")), 1)
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_RouterLink, {
                  to: "/new-account/email-password",
                  class: "col-12 mb-2 md:col-2 md:mb-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Button, { class: "w-full" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("newacc.email_pass_account")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("newacc.email_pass_account_help")), 1)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_RouterLink, {
                  to: "/new-account/vanity",
                  class: "col-12 mb-2 md:col-2 md:mb-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Button, { class: "w-full" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("newacc.create_vanity")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("newacc.create_vanity_help")), 1)
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_RouterLink, {
                  to: "/new-account/multisig",
                  class: "col-12 mb-2 md:col-2 md:mb-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Button, { class: "w-full" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("newacc.create_multisign_account")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t("newacc.create_multisign_account_help")), 1)
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_RouterLink, {
                  to: "/new-account/watch",
                  class: "col-12 mb-2 md:col-2 md:mb-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Button, { class: "w-full" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("newacc.watch_account")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t("newacc.watch_account_help")), 1)
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createVNode(_component_RouterLink, {
                  to: "/new-account/import-ed25519",
                  class: "col-12 mb-2 md:col-2 md:mb-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Button, { class: "w-full" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("newacc.import_account")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t("newacc.import_account_help")), 1)
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createVNode(_component_RouterLink, {
                  to: "/new-account/shamir",
                  class: "col-12 mb-2 md:col-2 md:mb-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Button, { class: "w-full" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("newacc.shamir_account")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t("newacc.shamir_account_help")), 1)
              ])
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})