import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, unref as _unref, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

import copy from "copy-to-clipboard";

export default /*@__PURE__*/_defineComponent({
  __name: 'AlgorandAddress',
  props: {
  address: String,
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (props.address?.length == 58)
    ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
        key: 0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(copy)(props.address))),
        plain: "",
        text: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(props.address.substring(0, 4)) + ".." + _toDisplayString(props.address.substring(props.address.length - 4)), 1)
        ]),
        _: 1
      })), [
        [_directive_tooltip, 'Address: ' + props.address + ' Tap to copy.']
      ])
    : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(props.address), 1))
}
}

})