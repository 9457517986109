import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'AccountType',
  props: {
  account: null,
  accountData: null,
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_Badge = _resolveComponent("Badge")!

  return (props.accountData?.rekeyedTo)
    ? (_openBlock(), _createBlock(_component_Badge, {
        key: 0,
        severity: "danger",
        value: _ctx.$t('acc_type.rekeyed')
      }, null, 8, ["value"]))
    : (props.account?.sk)
      ? (_openBlock(), _createBlock(_component_Badge, {
          key: 1,
          severity: "info",
          value: _ctx.$t('acc_type.basic_account')
        }, null, 8, ["value"]))
      : (props.account?.type == '2fa')
        ? (_openBlock(), _createBlock(_component_Badge, {
            key: 2,
            severity: "info",
            value: "2FA Multisig"
          }))
        : (props.account?.type == '2faApi')
          ? (_openBlock(), _createBlock(_component_Badge, {
              key: 3,
              value: "2FA API technical account"
            }))
          : (props.account?.params)
            ? (_openBlock(), _createBlock(_component_Badge, {
                key: 4,
                severity: "warning",
                value: _ctx.$t('acc_type.multisig_account')
              }, null, 8, ["value"]))
            : (props.account?.type == 'ledger')
              ? (_openBlock(), _createBlock(_component_Badge, {
                  key: 5,
                  severity: "success",
                  value: _ctx.$t('acc_type.ledger_account')
                }, null, 8, ["value"]))
              : (props.account?.type == 'wc')
                ? (_openBlock(), _createBlock(_component_Badge, {
                    key: 6,
                    severity: "success",
                    value: _ctx.$t('acc_type.wc_account')
                  }, null, 8, ["value"]))
                : (_openBlock(), _createBlock(_component_Badge, {
                    key: 7,
                    severity: "info",
                    value: _ctx.$t('acc_type.public_account')
                  }, null, 8, ["value"]))
}
}

})