export default {
  "404": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata 404 - Sayfa Bulunamadı"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aradığınız web sitesi bulunamıyor."])}
  },
  "footer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-Wallet - Açık kaynak PWA Algorand topluluk cüzdanı"])}
  },
  "login": {
    "new_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni cüzdan"])},
    "new_wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdan ismi"])},
    "new_wallet_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdan şifresi"])},
    "new_wallet_button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdan oluştur"])},
    "new_wallet_button_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdanı içe aktar"])},
    "new_wallet_help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdanınız tarayıcınızda saklanacaktır. Cüzdan şifresi, cüzdanı açıp cüzdan içindeki hesapları görebilmek ve işlemleri imzalayabilmek için gereklidir."])},
    "new_wallet_help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifrenin 12 veya daha fazla karakter uzunluğunda olmasını, küçük harf, büyük harf, rakam ve özel karakter içermesini öneririz.."])},
    "open_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdan aç"])},
    "select_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdan seç"])},
    "wallet_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdan şifresi"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kuvvet"])},
    "new_wallet_button_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdan aç"])},
    "basic_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel kullanım"])},
    "tether_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tether nasıl kullanılır?  (USDt)"])},
    "source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWallet kaynak kodu"])}
  },
  "global": {
    "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri dön"])},
    "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anasayfa"])},
    "copy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresi kopyala"])},
    "copied_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoya kopyalandı"])},
    "stop_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kamerayı durdur"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleniyor.."])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son hata"])},
    "keyword_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword Search"])}
  },
  "navbar": {
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Wallet"])},
    "toggle_nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gezinmeyi değiştir"])},
    "list_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaplarımı listele"])},
    "new_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni hesap"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlar"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık"])},
    "asset_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT oluştur"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSS"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gizlilik Politikası"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çıkış Yap"])},
    "vote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oylama"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAKAS"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdanbağlantısı"])}
  },
  "swap": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlıkları takas yap"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağ"])},
    "network_not_supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağ desteklenmiyor"])},
    "has_sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artık yalnızca özel anahtarlara sahip standart hesaplar desteklenmektedir"])},
    "swap_asset_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takas yapmak istediğiniz varlığı seçin"])},
    "swap_asset_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takas yapmak istediğiniz varlığı seçinp"])},
    "swap_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takas yapılacak tutarı ayarlayın"])},
    "get_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiyat teklifi al"])},
    "apps_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAKAS, hesabınızın uygulamalara dahil olmasını gerektirir"])},
    "apps_optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulamalara kaydolun"])},
    "execute_button_deflex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defex'i kullanarak uygulayın"])},
    "execute_button_folks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folks yönlendiricisini kullanarak uygulayın"])},
    "slippage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayma(fiyat) farkı yüzdesi"])}
  },
  "votemenu": {
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel Bakış"])},
    "my_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorularım"])},
    "ask_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir soru sor"])},
    "delegation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegasyon"])},
    "answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıtlar"])},
    "trusted_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güvenilir liste"])},
    "governance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönetim"])},
    "ams01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oy coini"])}
  },
  "govtoolsmenu": {
    "gen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap oluştur"])},
    "distribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algoyu dağıt 1->N"])},
    "optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönetişimi etkinleştirin"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplu ödeme N->1"])},
    "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesapları birleştir N->1"])}
  },
  "voteask": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain aracılığıyla soru sorun"])},
    "question_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soru başlığı"])},
    "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu, tablodaki soru listesinde görünecektir"])},
    "question_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorunun metni"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha fazla soru ayrıntısı içeren URL adresi (https:// ile başlayın)"])},
    "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişilerin soru hakkında daha fazla bilgi bulabileceği bir url adresi ekleyin"])},
    "max_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şu andan itibaren sorunun kapatılacağı ana kadarki blok sayısı"])},
    "calculated_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimum blok"])},
    "calculated_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahmini süre"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kod"])},
    "response_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıt seçeneğinin metni"])},
    "remove_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaldır seçeneği"])},
    "responses_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnsanlar sorunuza belirli yanıtlara oy vererek yanıt verecektir. Lütfen 2-N yanıtın kodlarını ve metinlerini sağlayın."])},
    "add_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni yanıt ekle"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soru kategorisi"])},
    "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgiye dayalı kategori oy delegasyonu yürürlükte"])},
    "submit_question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("accountName")), " hesabını kullanarak bir soru sorun"])}
  },
  "votedelegate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oylama yetkisi delegasyonu"])},
    "intro1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oy verme hakkınızı güvendiğiniz birine devredebilirsiniz. Bu arkadaşınız ya da tanınmış bir kişi olabilir."])},
    "intro2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finans alanında iyi bir arkadaşınız varsa, finans kategorisinde oy hakkınızı ona devredebilirsiniz. Eğer doğrudan sorulara oy vermiyorsa, sizin oy hakkınızı ve oy hakkını istediği başka birine devredebilir. bilir. Karşı taraf oy verdiğinde sizin oy gücünüzü kullanır ve fikrinizi temsil eder. Her zaman vekaleten kullanmış olsanız bile her konuda doğrudan oy kullanabilirsiniz. Doğrudan oy verirseniz oy hakkınız yalnızca sizin takdirinizde kalır."])},
    "intro3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oylama gücünüzü birkaç arkadaşınıza bölebilirsiniz. Oylama gücü dağıtımınızın ağırlığını puan olarak belirlersiniz. 1 numaralı arkadaşınız için 50 puan ve 2 numaralı arkadaşınız için 100 puan belirlerseniz, 1 numaralı arkadaşınızın 50 puanı olur /150 = Oylama gücünüzün %33'ü. Geri kalan 2. Arkadaşınız 100/150 = Oylama gücünüzün %67'sine sahip olacak."])},
    "intro4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhangi bir kategori, geri dönüş kategorisidir. Belirli bir sorunun kategorisi tanımlanmamışsa, tanımlanmışsa herhangi bir kategori kullanılacaktır."])},
    "category_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhangi bir kategori"])},
    "add_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap ekle"])},
    "wallet_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdan adresi"])},
    "delegate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabı heyete ekle"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belirli bir kategoriyi devret"])},
    "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkilendirmenizi bilgi tabanlı bir kategoride belirtebilirsiniz. ör. BT, finans, yönetişim"])},
    "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belirli bir kategori ekleyin"])},
    "submit_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşağıdaki butona tıklayarak, delegasyon tercihlerinizi blockchain'e depolayacaksınız."])},
    "submit_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("accountName")), " için yetki tercihlerini blok zincirine saklayın"])}
  },
  "voteoverview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel Bakış"])}
  },
  "votequestions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorular"])}
  },
  "votequestionlist": {
    "question_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soru başlığı"])},
    "no_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlk soru soran siz olun"])},
    "question_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soru metni"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yuvarlak olarak yerleştirildi"])},
    "maxround": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tura kadar açık"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaman"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soru soran"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm soruları listele"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soru kimliği"])},
    "current_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli tur"])},
    "round_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turun zamanı"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçenekler"])},
    "voting_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oylama kapatıldı"])},
    "latest_response": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("accountName")), " hesabı için en son oy seçildi"])},
    "vote_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("accountName")), " hesabıyla oy verin"])},
    "sum_trusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaplanan oy sayısı"])},
    "sum_coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaplanan coinlerin toplamı"])},
    "trusted_list_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güvenilen liste sonucu"])},
    "hypercapitalism_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 jeton 1 oy sonucu"])},
    "vote_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oy vermek istiyorsanız lütfen tercihlerinizi seçin. Her yanıt için tercihlerinizi 0'dan 100 puana kadar derecelendirebilirsiniz. Yalnızca bir yanıt seçmek istiyorsanız lütfen ona 100 puan verin, diğer tüm yanıtlara cevaplar 0 puan. Bilmiyorsanız oy vermenize gerek yoktur veya tüm cevaplara eşit puan verebilirsiniz. Bir cevaba 100 puan, diğer cevaba 20 puanla oy verirseniz, oy verme gücünüz ilk cevap 100/120 = %83, ikinci cevap ise %17 olacaktır. Tüm seçeneklere 100 puan verirseniz, oy gücü dağılımınız tüm seçeneklere 1 puan vermişsiniz gibi aynı olacaktır."])},
    "check_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonuçları kontrol et"])}
  },
  "votetl": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güvenilen liste yönetimi"])},
    "help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soru soran, bu sayfada güvenilir hesapların listesini yönetebilir. Her kişinin yalnızca bir güvenilir hesabı olabilir. Bunun yönetimi soruyu soran tarafından sağlanmalıdır. Oylama bittikten sonra iki oy hesaplaması vardır - biri kişi bir oy ve bir jeton bir oy.Oylama sistemi oylama sonucunun ne anlama geldiğini yorumlamaz.Oylama sistemi sadece oylama sistemi kurallarına göre oylama sonuçlarını hesaplar, örneğin oyların delegasyonu, oyların oranı, oyların geçerliliği oy vb."])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesapları güvenilenler listesine ekleyin - her satıra bir hesap"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesapları güvenilir listeden kaldır - her satıra bir hesap"])},
    "submit_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("accountName")), " için güvenilir listeyi blockchain'de depola"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n. turda eklendi."])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güvenilen hesap"])},
    "no_tl": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("accountName")), " hesabı için listelenen güvenilir hesap yok."])}
  },
  "voteanswerslist": {
    "no_answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorunun yanıtı henüz yok."])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tepki turu"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tepki süresi"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıtlayan"])},
    "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıt json'u"])}
  },
  "merchant": {
    "make_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme ağ geçidi"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öde"])},
    "to_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["algorand adresine"])},
    "please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağ"])},
    "matching_symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşleşen sembol"])},
    "network_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağ ücreti"])},
    "pay_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR kodunu kullanarak ödeme yapın"])},
    "pay_nativewallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand cüzdanını kullanarak ödeme yapın"])},
    "pay_webwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web cüzdanını kullanarak ödeme yapın"])},
    "cancel_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeyi iptal et"])},
    "payment_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme alındı"])},
    "go_back_to_merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satıcıya geri dön"])},
    "waiting_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme bekleniyor"])},
    "error_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık getirilirken hata oluştu: "])}
  },
  "gateway": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aracı olmadan ücretsiz açık kaynaklı ödeme ağ geçidi"])},
    "perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web sitenizde algo, USDC, USDT veya diğer varlıkları kabul etmeye başlayın. Finansal aracı olmadan kripto para birimini kabul ederek web sitenizdeki satışlarınızı artıracak ve karınızı artıracaksınız."])},
    "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasıl çalışıyor?"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benzersiz bir eşleşen sembol oluşturun, örneğin sipariş kimliği"])},
    "redirect_to_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıyı ağ geçidine yönlendir"])},
    "customer_pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri doğrudan algorand hesabınıza ödeme yapar"])},
    "customer_redirected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme başarılı olduktan sonra müşteri web sitenize geri yönlendirilecektir"])},
    "use_your_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paranızı kullanın. USDT bozdurup borsadan USD alabilir veya diğer mal veya hizmetler için USDT ile ödeme yapabilirsiniz"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güvenli mi?"])},
    "you_make_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme ayrıntılarını siz yaparsınız. Başarılı URL adresini ayarlarsınız ve URL adresini iptal edersiniz. Ödeme yapıldığında sizi bilgilendiririz. Ödemeyi kabul ettiğiniz arka uçta kontrol etmeniz ve ödemenin alındığını bildirmeniz gerekir. müşteri."])},
    "opensource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu proje açık kaynaktır. Ticari amaçlarla kopyalayabilir, değiştirebilir veya kendi özel ortamınızda çalıştırabilirsiniz"])},
    "just_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme ağ geçidi yalnızca javascript içeren bir HTML web sitesidir. Resmi algoritma düğümleri dışında başka hiçbir arka uç kullanılmaz."])},
    "no_restrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağ geçidini kullanmak için bir AWallet hesabına ihtiyacınız yok."])},
    "allowed_parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İzin verilen parametreler"])},
    "parameters_perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu parametrelerden herhangi birini ayarlayabilirsiniz. Gerekli alanlar yalnızca ödemenizi almak istediğiniz adresi ve işlem tutarını içerir. Ödeme adresi iki bölümden oluşur: ödeme bilgileri ve ağ geçidi ayarları. Her iki alan da base64, \"/\" ile ayrılmış olarak kodlanmıştır. Lütfen URL'yi doğru şekilde kodladığınızdan emin olun. Ödeme bilgisi, örneğin QR kodlarında kullanılan temel algoritma ve protokol adresidir."])},
    "payment_info_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İzin verilen parametreler"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["varlık temel birimini kullanın. Örn. 1 ALGO = 1000000"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mikroalgo cinsinden miktar. Örn. 0,001 ALGO = 1000"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata ayıklama amaçlı ağ"])},
    "settings_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İzin verilen ayarlar"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı ödeme sonrasında müşterinin yönlendirildiği URL adresi"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşterinin ödemeyi iptal et'e tıkladıktan sonra yönlendirileceği URL adresi"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Örnek"])},
    "turn_on_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derleme aracını açın"])},
    "turn_off_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derleme aracını kapat"])},
    "tether": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı ana ağı"])},
    "algorand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem için varlık kimliğini belirleyebilirsiniz"])},
    "error_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen en az hesap, tutar ve varlığı girin"])},
    "error_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL adresi https:// ile başlamalıdır"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulan düğme"])},
    "payment_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme bilgisi"])},
    "callback_configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri arama yapılandırması"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulan bağlantı"])},
    "html_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML bağlantısı örneği"])}
  },
  "receive": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeyi al"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adrese ödeme al"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutar"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR koduna ondalık basamak hakkında bilgi"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alıcı adresinin önerilen etiketi"])},
    "noteeditable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not kullanıcı tarafından düzenlenebilir"])}
  },
  "acc_overview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaba genel bakış"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni ödeme yap"])},
    "receive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeyi al"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutar"])},
    "amount_without_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekleyen ödüller olmadan tutar"])},
    "rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödüller"])},
    "pending_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekleyen ödüller"])},
    "reward_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödül tabanı"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tur"])},
    "apps_local_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulamaların yerel durumu"])},
    "apps_total_schema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulamaların toplam şeması"])},
    "assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlıklar"])},
    "created_apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulamalar oluşturuldu"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
    "multisignature_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoklu imza eşiği"])},
    "multisignature_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoklu imza adresleri"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yenile"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlemler"])},
    "no_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiçbir işlem bulunamadı"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tür"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaman"])},
    "tr_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutar"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderen"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alıcı"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alıcı ödülleri"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanan tur"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komisyon ücreti"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabı sil"])},
    "delete_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabı sil"])},
    "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu hesabı cüzdanınızdan silmek istediğinizden emin misiniz?"])},
    "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu hesabı sil"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap silindi"])},
    "asset_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlığı etkinleştirin"])},
    "ask_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir soru sorun"])},
    "rekey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabı yeniden anahtarlayın"])},
    "rekeyedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaba yeniden anahtarlandı"])},
    "account0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defter birincil hesabı"])},
    "slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defter yuvasındaki hesap"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdanbağlantısı"])},
    "payment_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme ağ geçidi"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dışa aktar"])},
    "no_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu hesapta henüz varlık yok"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylemler"])},
    "pay_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağdaki diğer hesaplara para veya jeton gönderin."])},
    "swap_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEX toplayıcı hizmetlerini kullanarak bir jetonu diğerine değiştirebilirsiniz"])},
    "rekey_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınızın özel anahtarının başkasının eline geçmesinden korkuyorsanız, hesabınızı yeni özel anahtara yeniden anahtarlayabilir ve orijinal adresinizi güvende tutabilirsiniz."])},
    "asset_optin_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand ağında varlık almak istiyorsanız o token için hesap açmalısınız."])},
    "receive_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşterilerinizden veya arkadaşlarınızdan size para göndermelerini isteyebilirsiniz. Bu sayfa, başkalarının tek tıklamayla bunu yapabilmesi için ödeme QR kodu oluşturacaktır."])},
    "export_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel anahtarlara sahip hesaplar için shamir yedekleme anımsatıcıları veya algorand anımsatıcıları oluşturabilirsiniz."])},
    "arc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC 14 Kimlik Doğrulaması"])},
    "arc14_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC 14, imzalı işlemleri kullanan kimlik doğrulama standardıdır. Kimlik doğrulama belirteçlerini bu sayfada oluşturabilirsiniz."])},
    "account_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap işlemleri"])},
    "asset_optinArc200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC200 varlığını tercih et"])},
    "asset_optinArc200_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınızda ARC200 varlıklarını izleyebilirsiniz. ARC200 varlıkları, herkesin kullanıcının hesabına varlık alabilmeyi seçebileceği özelliğe sahip akıllı sözleşmelerdir. ARC200 varlıkları tehlikeli olabilir ve Akıllı sözleşme böylece ona tamamen güvenebilirsiniz."])},
    "scheduled_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payment"])},
    "scheduled_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can setup and manage a scheduled periodic payments to another accounts"])},
    "hide_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide account"])},
    "unhide_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unhide account"])},
    "vote_first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote First Round"])},
    "vote_last_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Last Round"])},
    "vote_key_dilution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Key Dilution"])},
    "selection_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selection Key"])},
    "vote_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Key"])},
    "stateproof_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StateProof Key"])},
    "realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realm"])},
    "arc14msig_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please sign ARC14 authentication transaction and return back to this form."])},
    "hasArc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC14 auth has been loaded."])},
    "generating_keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generating participation keys.. Please be patient, the participation node is performing CPU sensitive task."])},
    "button_sign_keyreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign key registration tx"])},
    "button_close_custom_keyreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close KeyReg tx form"])},
    "button_activate_wizzard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate wizard"])},
    "button_fetch_realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 1/4: Fetch ARC14 realm"])},
    "button_sign_arc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2/4: Sign ARC14 realm"])},
    "button_sign_arc14_msig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2/4: Multisign ARC14 realm"])},
    "button_load_participatin_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 3/4: Load participation data"])},
    "button_sign_keyreg_tx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 4/4: Sign participation tx"])},
    "button_custom_keyreg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom KeyReg tx"])},
    "making_account_online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting your account to online state. Please wait a while"])},
    "making_account_offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting your account to offline state. Please wait a while"])}
  },
  "acc_overview_assets": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "search_by_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsme göre ara"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tür"])},
    "search_by_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık türüne göre ara"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kimlik"])},
    "search_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kimliğe göre ara"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutar"])},
    "search_by_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutara göre ara"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylemler"])}
  },
  "optin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaptaki varlık transferlerini kabul et"])},
    "searchById": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık kimliğini biliyorum"])},
    "assetId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık Kimliği"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık Adı"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık bul"])},
    "assetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık hakkında bilgi"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam"])},
    "unit_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birim adı"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ondalıklar"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "metadata_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata hash"])},
    "default_frozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturucu adresi"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönetici address"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri alma address"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donmuş adres"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezerve adresi"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylem"])},
    "optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabul et"])},
    "dialog_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlığı etkinleştirin"])},
    "optin_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu varlığı etkinleştirmek için 0,001 algo ödemek istediğinizden emin misiniz? Etkinleştirdikten sonra bu varlığı hesabınıza alabileceksiniz."])},
    "optin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabul etmek istiyorum"])},
    "asset_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık bulunamadı"])},
    "asset_opt_in_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık başarıyla etkinleştirildi"])}
  },
  "accounts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaplara genel bakış"])},
    "no_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap bulunamadı"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap adı"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutar"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öde"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdanbağlantısı"])},
    "show_on_netowork_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağ hesaplarını göster"])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA kodu"])}
  },
  "newacc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni hesap"])},
    "create_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel hesap oluştur"])},
    "import_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabı içe aktar"])},
    "create_multisign_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoklu imza hesabı oluştur"])},
    "watch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabı izle"])},
    "write_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25 kelimelik anımsatıcı ifadeyi yazın"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dahili hesap adı"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap oluştur"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "multisig_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisignature hesabı, yalnızca hesap oluşturma sırasında listelenen N hesabın işlemi imzalaması durumunda işlemleri işleyebilir."])},
    "select_account_from_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdanınızdaki mevcut hesapları seçin"])},
    "add_other_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkadaşlarınızın hesaplarını ekleyin - her satıra bir hesap"])},
    "trashold_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlemi imzalamak için kaç hesabın gerekli olduğunu seçin"])},
    "create_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabı oluşturmak için anımsatıcı ifadeyi yedeklediğinizden emin olun. İfadeyi şimdi göstermek güvenli mi?"])},
    "show_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anımsatıcıyı göster"])},
    "position_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n. konumundaki kelime nedir."])},
    "mnemonic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anımsatıcı kelimeleri görüntülenen sırayla yazın, kopyalayın veya fotoğrafını çekin. Bir sonraki adım, bu ifadedeki bazı kelimeleri onaylamak olacak ve bunu tarayıcınızın şifreli deposuna kaydedeceğiz. Güvenlidir Bu anımsatıcıyı saklamak ve bu adresi hesaba kaydetmeden kullanmak için. Anımsatıcı ifadeyi asla kaybetmediğinizden emin olun."])},
    "start_challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meydan okumayı başlat"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni oluştur"])},
    "hide_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anımsatıcıyı gizle"])},
    "drop_phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İfadeyi bırak"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR kodundan tara"])},
    "create_vanity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel hesap oluştur"])},
    "vanity_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres şununla başlayacak"])},
    "vanity_mid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres şunları içerecektir"])},
    "vanity_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres şununla bitecek"])},
    "vanity_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlenen adres sayısı"])},
    "vanity_button_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresi bul"])},
    "vanity_button_stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aramayı durdur"])},
    "vanity_workers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanılacak iş parçacığı sayısı"])},
    "vanity_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu hesabı kullan"])},
    "auto_lock_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik kilitleme özelliği kapalı"])},
    "ledger_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HW Cüzdan - Defter Hesabı"])},
    "wc_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdan Bağlantı Hesabı"])},
    "twofa_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA Hesabı"])},
    "show_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Kodunu Göster"])},
    "email_pass_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta ve Şifre hesabı"])},
    "shamir_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir yedekleme kurtarma"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni hesap işlemleri"])},
    "create_basic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand temel hesabı, özel anahtara sahip ED25519 hesabıdır. Bu yöntem, hesabınız için sır oluşturmak için bilgisayarınızın rastgele sayı oluşturucusunu kullanır."])},
    "ledger_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En güvenli çözümlerden biri gizli anahtar oluşturma işlemini özel bir cihazla gerçekleştirmektir. Bu hesap, hesabınızı oluşturmak ve imzalama işlemlerini gerçekleştirmek için Ledger cihazını kullanır."])},
    "wc_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdan bağlantı hesap türünü kullanarak arkadaşınızın cüzdanına bağlanabilirsiniz. Örneğin, çoklu imzanın bir parçası olarak ona mesajı imzalama isteği gönderebilirsiniz."])},
    "twofa_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İki faktörlü hesap çoklu imzalı hesaptır; burada bir hesap sizin seçtiğiniz bir hesaptır, örneğin genel muhasebe hesabı veya e-posta şifresi hesabı, ikinci hesap kurtarma hesabıdır - ideal olarak aynı güvenli depolama alanında olmayan hesap ve üçüncü hesap ise doğru iki faktörlü pin kodunu girdiğinizde işlemleri imzalayan sunucudaki iki faktörlü hesap."])},
    "email_pass_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC76 E-posta ve Şifre hesabı, herhangi bir metinden algorand hesabı oluşturmanıza olanak tanır. Metin hashlenir ve algorand hesabınız için tohum olarak 32 bayt oluşturur. Bu, bilgisayarınızdaki diğer rastgele sayı üreteçlerine alternatif olabilir veya iki faktörlü hesapta veya genel çoklu imza hesabında kullanabileceğiniz hw cihazı."])},
    "create_vanity_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ED25519 hesabınız için güzel hesap adresini kaba kuvvetle kullanabilirsiniz. Bu yöntem, hesap oluşturmanın birçok kombinasyonunu dener ve hesap adresinin ilk veya son harflerini seçmenize olanak tanır."])},
    "create_multisign_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisig hesapları en güvenli hesaplardır. Birden fazla defter cihazı ve farklı rasgele sayı oluşturucularla birlikte kullanılırsa, bu, doğrudan algoritma ve blok zincirine sabit kodlanan kurumsal düzeyde güvenlik sağlar."])},
    "watch_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağdaki herhangi bir hesabı takip edebilirsiniz."])},
    "import_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabı algoritma ve kurtarma anımsatıcısından kurtarabilirsiniz."])},
    "shamir_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabı shamir yedekleme kurtarma anımsatıcılarından kurtarabilirsiniz."])},
    "create_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlk hesabınızı oluşturun"])},
    "no_accounts_at_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili ağ için hesap yok"])},
    "basic_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel seçenekler"])},
    "advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş seçenekler"])},
    "skip_challange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip challange"])},
    "new_account_challange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New account challange"])},
    "click_to_show_positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to show positions"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])}
  },
  "arc76account": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta ve şifre hesabı (ARC-0076)"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta adresinizin ve şifrenizin birleşimi, ilk algorand hesabınız için tohum baytları oluşturacaktır. Şifreyi veya e-posta adresini yalnızca başka bir hesaba yeniden anahtarlayarak değiştirebilirsiniz."])},
    "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC-0076 standardı, PBKDF2-999999-SHA256 algoritması ile kullanılacaktır. Şifre uzunluğunun en az 50 karakter uzunluğunda olmasını öneriyoruz. Bu tür hesaplar, ikincil tip rastgele sayı üreteci olarak kullanılabilir ve güçlendirilebilir. Güvenliğiniz. Lütfen sırrınızı güvende tutun ve tekrar kullanmayın."])},
    "arc_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC-0076 TASLAK durumunda. Spesifikasyon değişebilir. Lütfen bu uygulamayı deneysel kullanım için düşünün."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta"])},
    "select_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen çok çok çok güvenli bir şifre seçin."])},
    "save_password_switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreyi kaydet"])},
    "password_not_stored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dikkat edin. Şifrenizi saklamıyoruz. Yalnızca hesabınızın genel adresini ve e-posta adresini güvenli depolama alanında saklayacağız. Bu hesapla işlem yapacağınızda şifreyi girmeniz istenecektir."])},
    "gui_not_implemented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saklanan şifre olmadan işlem imzalamaya yönelik GUI şu anda uygulanmıyor."])}
  },
  "import": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdanı içe aktar"])},
    "wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdan adı"])},
    "wallet_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdan dosyası"])},
    "import_wallet_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdanı içe aktar"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha önce dışa aktarılan cüzdanları içe aktarabilirsiniz. Cüzdan şifresi dışa aktarılan dosyanın içinde kalır, böylece içe aktarma sonrasında bu cüzdana daha önce kullandığınız şifreyle erişebilirsiniz. Giriş yaptıktan sonra ayarlar sayfasından şifreyi değiştirebilirsiniz."])}
  },
  "acc_type": {
    "basic_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel hesap"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoklu imza hesabı"])},
    "public_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel hesap"])},
    "rekeyed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden anahtarlanan hesap"])},
    "ledger_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HW genel muhasebe hesabı"])},
    "wc_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdanbağlantısı hesabı"])}
  },
  "account": {
    "select_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hessap seç"])}
  },
  "pay": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeyi yap - şuradan"])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamerayı değiştir"])},
    "note_is_b64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Base64 ile kodlanmıştır. Etkinleştirilirse, not alanına base64 girişini gönderebilir ve bunu ham veri olarak ağa gönderebilirsiniz."])},
    "selected_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili hesap"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoklu imza hesabı"])},
    "create_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teklif oluştur"])},
    "sign_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teklifi imzala ve gönder"])},
    "signature_from_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen arkadaşınızın imzasını buraya girin"])},
    "load_multisig_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoklu imza verilerini yükle"])},
    "pay_to_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdan hesabına öde"])},
    "pay_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer hesaba öde"])},
    "store_other_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para göndereceğiniz hesabı adres defterinde saklamanız daha iyi olur"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutar"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komisyon ücreti"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not"])},
    "review_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeyi incele"])},
    "review_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen ödemenizi inceleyin"])},
    "from_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaptan"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaba öde"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam"])},
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem ayrıntıları"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tür"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlk tur"])},
    "last_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son tur"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis kimliği"])},
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiket"])},
    "to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alıcı"])},
    "signatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzalar"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzalandı"])},
    "not_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzalanmadı"])},
    "sign_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birlikte imzala"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmza"])},
    "send_to_other_signators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu verileri diğer imzacılara gönder"])},
    "send_to_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağa gönder"])},
    "state_sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme ağa gönderiliyor"])},
    "state_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme ağa gönderildi. Tx: "])},
    "state_waiting_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağ onayı bekleniyor."])},
    "state_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onay alındı. Ödemeniz blokta"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık"])},
    "state_error_not_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme muhtemelen ağa ulaşmadı. Çevrimdışı mısınız? Lütfen hesabınızı kontrol edin"])},
    "set_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimumu ayarla"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çevre"])},
    "pay_to_address_malformed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme adresi hatalı görünüyor."])},
    "asset_failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstenen varlığın yüklenemediği anlaşılıyor"])},
    "asset_too_small_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bakiyeniz istenen ödeme tutarının altında görünüyor"])},
    "rekey_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaba yeniden anahtar verin"])},
    "rekey_to_wallet_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdan hesabına yeniden anahtar verin"])},
    "rekey_to_external_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harici hesaba yeniden anahtar verin"])},
    "rekey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabı yeniden anahtarlayın"])},
    "rekey_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen bu işleme özellikle dikkat edin. Artık hesap, işlemleri tamamlayabilmek için farklı bir özel anahtar kullanacak. Yeni hesabın özel anahtarına sahip değilseniz büyük olasılıkla kaybedersiniz işlemleri imzalama yeteneği."])},
    "combine_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer imzalayanların imzalarını birleştir"])},
    "combine_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzaları birleştir"])},
    "return_to_wc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect'e Geri Dön"])},
    "select_account_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeyi yapmak istediğiniz hesabı seçin"])},
    "create_multisig_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoklu imza teklifi oluştur"])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA kodu"])},
    "sign_arc14_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkilendirme isteğini imzala"])},
    "toggle_send_to_others_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başkalarına Gönder formunu aç/kapat"])},
    "toggle_combine_with_others_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğerleriyle birleştirme formunu aç/kapat"])},
    "process_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeyi işle"])},
    "tx_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem türü"])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlar"])},
    "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucu"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çevre"])},
    "mainnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana ağ"])},
    "testnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test ağı"])},
    "devnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devnet'e Ulaşın"])},
    "sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korumalı alan"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Bilgisayar"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belirteç"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdan şifresi"])},
    "oldpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eski şifre"])},
    "newpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni şifre"])},
    "repeatpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni şifreyi onayla"])},
    "backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdanı yedekle"])},
    "backup_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdanınızı yedekleyebilir ve başka bir bilgisayara aktarabilirsiniz."])},
    "create_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yedek oluştur"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İndir"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdanı sil"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güç"])},
    "updated_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre güncellendi"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel sunucu ayarları"])},
    "protocol_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protokol işleyicisini kaydedin"])},
    "protocol_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web+algorand:// işleyicisini kaydedin"])},
    "protocol_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protokol işleyicisi başarıyla güncellendi"])},
    "dev_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geliştiriciler için bilgileri göster"])},
    "update_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreyi güncelle"])}
  },
  "transaction": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem detayı"])},
    "tr_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem Kimliği"])},
    "tr_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem türü"])},
    "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not"])},
    "note_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ham veriler"])},
    "note_decoded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifresi çözülmüş veriler"])},
    "tr_close_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödülleri kapat"])},
    "closing_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapanış tutarı"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanan tur"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komisyon ücreti"])},
    "first_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlk geçerli"])},
    "genesis_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis Kimliği"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis karması"])},
    "intra_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yuvarlak içi ofset"])},
    "last_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son geçerli"])},
    "payment_tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme işlemi"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutar"])},
    "close_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapatma tutarı"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alıcı"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alıcı ödülleri"])},
    "round_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tur süresi"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderen"])},
    "sender_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderen ödülleri"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzalama"])},
    "created_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulan varlık"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık adı"])},
    "asset_creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık yaratıcısı"])},
    "asset_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık yöneticisi"])},
    "asset_reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık rezervi"])},
    "asset_freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlığın dondurulması"])},
    "asset_clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık geri alma"])},
    "asset_unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık birimi adı"])},
    "asset_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık toplamı"])},
    "asset_decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık ondalık sayıları"])},
    "asset_defaultfrozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık varsayılanı dondurulmuş"])},
    "asset_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık URL'si"])},
    "asset_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık meta verileri"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süre"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup kimliği"])}
  },
  "privacy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gizlilik politikası"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakkımda hangi özel bilgileri topluyorsunuz?"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yok. Her internet hizmeti için günlüklerde saklanan IP adresi ve tarayıcının yanı sıra adınız, e-posta adresiniz veya adresiniz gibi hiçbir türde özel bilgi toplamıyoruz."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çerezlerde ne saklıyorsunuz?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiçbir şey. Çerez kullanmıyoruz. Cüzdanınızın saklanması için IndexedDB adı verilen kalıcı yerel depolamayı kullanıyoruz."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analitik bilgilerini nasıl topluyorsunuz?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanmıyoruz. Kendinizi daha güvende hissetmeniz için Google Analytics gibi herhangi bir analiz hizmeti kullanmıyoruz. Web sitesi tamamen Javascript içeren HTML'den oluşuyor. IP adresinizi yalnızca siz talepte bulunduğunuzda biliyoruz. Ayarlarınıza ve algod sunucunuza veya indeksleyicinize bağlı olarak seçilen sunucu IP adresinizi de bilebilir."])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu hizmetin kullanımı gördüğüm reklamları etkiler mi?"])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır. Reklam sunucularının herhangi bir kullanıma ilişkin bizden herhangi bir bilgisi yoktur. Örneğin Google'ın bu hizmeti kullandığınızı bilmesinin tek yolu, bu siteye Google aramasından erişmenizdir."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu hizmeti kim sağlıyor?"])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scholtz & Company, jsa bu hizmeti oluşturdu. Şirket Kimliği: 51 882 272, KDV Kimliği: SK2120828105, Bratislava, Slovakya merkezli. Bu, a-wallet.net resmi web sitesinde çalışıyorsa, şirket aynı zamanda servis sağlayıcıdır. Şirket herhangi bir hizmetten veya uygulamadaki herhangi bir hatadan sorumlu değildir."])}
  },
  "assetcreate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni NFT/ASA"])},
    "show_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş formu göster"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolay ad"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaratıcının adresi"])},
    "hide_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş formu gizle"])},
    "creator_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önce adres oluşturun veya adresi içe aktarın"])},
    "default_fronzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dondurulmuş: İşlem yapılmadan önce kullanıcı hesaplarının dondurulmasının kaldırılması gerekir"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ondalık sayılar: varlık birimi hesaplaması için ondalık sayıların tamsayı sayısı"])},
    "totalIssuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu varlığın dolaşıma açık toplam sayısı"])},
    "unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcıya varlık birimlerini görüntülemek için kullanılır"])},
    "assetURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlıkla ilgili isteğe bağlı URL"])},
    "assetMetadataHash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlıkla ilgili bir tür isteğe bağlı karma taahhüdü. Base64 biçimi"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönetici, varlık için yönetici hesabını değiştirebilir, ayırabilir, dondurabilir ve geri alabilir."])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezerv adresi: Belirtilen adres, varlık rezervi olarak kabul edilir (özel ayrıcalıkları yoktur, bu yalnızca bilgilendirme amaçlıdır)"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresi dondur: Belirtilen adres, kullanıcı varlık varlıklarını dondurabilir veya çözebilir"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clawback adresi: Belirtilen adres, kullanıcının varlık sahipliklerini iptal edebilir ve bunları başka adreslere gönderebilir"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturma işleminde ayarlanan not"])},
    "create_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT/ASA oluştur"])}
  },
  "new_account_ledger": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledger hesabı ekle"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defter, özel anahtarınızı saklayabilen fiziksel bir cihazdır - HW cüzdanıdır. Varlıkları algorand üzerinde depolamak için maksimum güvenlik, tüm imza sahiplerinin defter cihazları tarafından güvence altına alındığı çoklu imzalı hesaptır."])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son hata"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defter'i bağla ve algoritma adresini yükle"])},
    "slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yuva"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "primary_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birincil adres"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap adı"])},
    "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresi cüzdana kaydet"])}
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sık sorulan sorular"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir Cüzdan Hakkında"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo cüzdanı başlangıçta Scholtz & Company, jsa tarafından oluşturuldu. Açık kaynak, yalnızca HTML, kurumsal dostu ve güvenlik, kullanımının ana avantajlarıdır. Algorand logosu, -algo- ve -algorand kelimeleri  Algorand, Inc.'in ticari markasıdır ve https://www.algorand.com/about/media-kit/ algorand medya yönergeleriyle birlikte kullanılır."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdan verilerimin güvenliği nasıl sağlanıyor?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verileriniz yalnızca tarayıcıdadır. Kalıcı IndexedDB'de depolanır. Her cüzdan, şifrenizle birlikte aes256 kullanılarak şifrelenir. Yeterince güçlü bir şifre oluşturursanız, cüzdanınız asla kaba kuvvete maruz kalmaz. Şifreniz bilgisayarınızdan ayrılmayın ve yalnızca tarayıcı önbelleğinde bulunur. Önbellek, giriş yaptıktan sonra 5 dakika içinde yok edilir."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdan şifrem internete giriyor mu?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asla"])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdan şifremi nasıl değiştirebilirim?"])},
    "a4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş yapın ve cüzdanınıza erişin."])},
    "a4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlara gidin."])},
    "a4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eski şifreyi, yeni şifreyi yazın ve yeni şifreyi onaylayın."])},
    "a4_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formu gönderin, şifreniz değiştirilecektir."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdanımı başka bir bilgisayara taşıyabilir miyim?"])},
    "a5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş yapın ve cüzdanınıza erişin."])},
    "a5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlara gidin."])},
    "a5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yedekleme bölümüne gidin ve verilerinizi yerel bir dosyaya kaydedin."])},
    "a5_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer bilgisayarda yeni cüzdana gidin, cüzdanı içe aktarın ve indirilen dosyayı diğer tarayıcı deposuna yükleyin."])},
    "a5_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdana daha önce kullandığınız şifreyle erişin. Şifreyi ayarlardan değiştirebilirsiniz."])},
    "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynak kodunu görüntüleyebilir miyim?"])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet. https://github.com/scholtz/wallet/ Bu cüzdan açık kaynak olarak yapılmıştır ve ticari amaçlar da dahil olmak üzere herhangi bir nedenle kullanılması, kopyalanması veya değiştirilmesi ücretsizdir. Katılma imkanınız varsa projede lütfen çok nazik olun ve iyileştirmenizle birlikte bir çekme isteği oluşturun."])},
    "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projeye nasıl yardımcı olabilirim?"])},
    "a7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand adresine yapılan bağış AWALLETCPHQPJGCZ6AHLIFPHWBHUEHQ7VBYJVVGQRRY4MEIGWUBKCQYP4Y, projenin devam etmesine yardımcı olacaktır. Proje, Algorand Inc. veya Algorand Vakfı tarafından finanse edilmemiştir."])},
    "a7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projeyi çevirmek veya çeviriye yardımcı olmak için lütfen https://github.com/scholtz/wallet/tree/master/src/locales adresine gidin, çeviri dosyasını kendi dilinize kopyalayın ve çekme isteğinde bulunun ."])},
    "a7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programcıysanız lütfen kod incelemesi yapın veya bu projenin daha iyi olması gerektiğini düşündüğünüz herhangi bir şekilde bize yardımcı olun."])},
    "q8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoklu imza hesabını nasıl oluşturabilirim?"])},
    "a8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öncelikle özel anahtarlara sahip bir hesap oluşturun veya herkese açık bir hesap oluşturun. Onlara amaçlarını hatırlamanıza yardımcı olacak isimler vermek daha iyidir."])},
    "a8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bir adres oluşturmaya gidin ve çoklu imza adresini seçin."])},
    "a8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlemleri imzalayabilen tüm hesapları seçin ve ağın çoklu imza ödemesini kabul etmesi için minimum sayıya uyan imza sayısını seçin."])},
    "a8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoklu imza hesabını oluşturduktan sonra bu hesaba hesap listesi sayfasından erişebilirsiniz."])}
  },
  "connect": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect'i kullanarak DApp'lere bağlanın"])},
    "uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cüzdanbağlantısı URI'si"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlan"])},
    "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantıyı kes"])},
    "sendBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DApp'e geri gönder"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddet"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ya da"])},
    "authaddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkili adres"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dizin"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tür"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderen"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutar"])},
    "rekeyto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden Anahtarla"])},
    "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yöntem"])},
    "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri Kimliği"])},
    "request_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstek Kimliği"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaldır"])},
    "peer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eş"])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlandı"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumlar"])},
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstekler"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ücret"])},
    "total_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam ücret"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmza"])},
    "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panodan bağlan"])},
    "sign_txs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen bireysel işlemleri imzalayın"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İmzalandı"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kime"])},
    "validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerlilik"])},
    "rounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tur"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup"])},
    "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama dizini"])},
    "app_args": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama Argümanları"])},
    "app_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama Hesapları"])},
    "app_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama Yabancı Varlıkları"])},
    "boxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutular"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis Kimliği"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis Karması"])},
    "session_proposals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum önerileri"])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamerayı değiştir"])},
    "init_wc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet Connect bağlantısını başlat"])},
    "sign_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümünü imzala"])},
    "proposer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proposer"])},
    "all_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use all wallet accounts"])}
  },
  "new_account_wc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect hesabını ekleyin"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap adı"])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son hata"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR kodunu tarayın"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresi cüzdana kaydet"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URI'yi panoya kopyala"])},
    "show_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR kodunu göster"])}
  },
  "new_account_2fa": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA Hesabı"])},
    "help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA hesabı, imza için gerekli eşik değeri 2 olan 3 hesap kurulumuyla çoklu imzalı hesap oluşturmanıza olanak tanır."])},
    "help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlk hesap, cüzdanınızın ele geçirilmesi durumunda bilgisayar korsanının işlemleri sizin adınıza imzalayamayacağı şifre korumalı bir hesap olabilir. 2FA cihazınız bozulursa veya 2FA hizmeti çökerse hesabınızı yeniden anahtarlayabilirsiniz. birinci ve ikinci hesabınızla başka bir 2FA çoklu imzaya geçin."])},
    "help3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA hizmeti, ele geçirilebilecek üçüncü taraf hizmetidir. Eğer bilgisayar korsanı özel anahtarınızı alırsa ve 2fa hizmetinin özel anahtarını alırsa, sizin adınıza işlemleri imzalayabilir. 2FA hizmetini kullanarak bilgisayar korsanının saldırı olasılığını artırırsınız. Birincil veya kurtarma hesabınızla işlem imzalama olanağına sahip olanlar, çoklu imza hesabınızdan işlem imzalayamaz."])},
    "2fa_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA sağlayıcısı"])},
    "hot_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıcak hesap"])},
    "cold_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soğuk depolama hesabı"])},
    "request_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA QR kodunu iste"])},
    "scan_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen QR kodunu 2FA uygulamanızla tarayın"])},
    "manual_entry_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuel giriş anahtarı"])},
    "confirm_2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurulumu onayla"])},
    "confirm_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurulumu onaylamak için lütfen kimlik doğrulama uygulamanızdan 2FA kodunu yazın. Onaylandıktan sonra 2FA gizli kodunu tekrar istemek mümkün değildir."])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA kodu"])},
    "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresi kaydet"])}
  },
  "onlineofflinedialog": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınızın bakiyesiyle ağı koruyun"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağdaki blokları doğrulamak için genel katılım düğümünü seçebilirsiniz. Genel algod sunucusunda yeni katılım anahtarları üretecek ve bu katılım anahtarlarının blok verilerini toplamasına izin vermek için işlemi imzalayacaksınız. Bu size maliyet olarak yansıyacaktır. ağ üzerinde bir işlem (0.001 algo). Çevrimiçi hesap durumu, özel anahtarınızın internete açık olduğu anlamına gelmez. Hesabınızın, blockchain'i koruyan çevrimiçi algod düğümünde kayıtlı olduğu anlamına gelir."])},
    "makeOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabı çevrimiçi yapın"])},
    "makeOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabı çevrimdışı yap"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algod ana bilgisayarı"])}
  },
  "new_account_shamir": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir yedek hesap kurtarma"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir yedekleme, anımsatıcıyı M hesabının kurtarma eşiği olduğu N parçaya bölme yöntemidir."])},
    "mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anahtar kelimeler"])},
    "add_mnemnic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anahtar kelimeleri ekle"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabın adı"])},
    "recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurtar"])}
  },
  "account_export": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabı dışa aktar"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu sayfada özel anahtarınızı algorand'ın anımsatıcı veya shamir yedeği biçiminde dışa aktarabilirsiniz."])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen cüzdan şifrenizi girin"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam et"])},
    "algo_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand anahtar kelimleri"])},
    "shamir_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir yedeği"])},
    "toggle_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR kodunu değiştir"])},
    "recovery_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurtarma eşiği"])},
    "number_of_mnemonics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anahtar kelimelerin sayısı"])},
    "generate_shamir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir anahtar kelimleri oluştur"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önceki"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonraki"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir dizini"])},
    "shamir_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen anımsatıcıyı çok güvenli bir yerde saklayın. Hepsini yazdığınızdan emin olun. Shamir yedeklemesinin bazı bölümlerini kontrol edin ve kurtarma eşiğini tek bir yerde saklamayın."])},
    "algo_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen anımsatıcıyı çok güvenli bir yerde güvenceye alın."])},
    "dev_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geliştirici bilgisi"])}
  },
  "arc200": {
    "optin_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaba ARC 200 varlığını ekle"])},
    "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC200 Uygulama Kimliği"])},
    "fetch_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlık bilgilerini getir"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıfırla"])},
    "examples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Örnek varlıklar"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembol"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ondalıklar"])},
    "supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam arz"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap bakiyesi"])},
    "is_opted_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap varlık alabilir"])},
    "create_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu hesap için bu varlık için kutu oluştur"])},
    "save_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varlığı hesabınıza kaydedin"])}
  },
  "scheduled_payments": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payments management"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With decentralized scheduler you will deploy the smart contract - escrow account which will be used for purpose to allow any executor perform withdrawal from your escrow account to the destination account when the time comes. Executors are incentivized to execute your scheduled task with vision of receiving small fee paid in ASA.Gold token - 0.001 gram of gold."])},
    "new_scheduled_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New scheduled payment"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay to"])},
    "send_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset to send"])},
    "create_escrow_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create escrow account"])},
    "list_of_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of active payments"])},
    "no_payment_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No scheduled payments has been found"])},
    "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow app id"])},
    "app_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow app address"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "fee_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee balance"])},
    "execution_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution fee"])},
    "description_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this detail overview of the scheduled payment you can see the current asset balance of the escrow account and you have actions to deposit more assets to the escrow account or to executors."])},
    "details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payment details"])},
    "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])},
    "deposit_fee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit asa.gold token to fee pool smart contract"])},
    "fee_asset_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution fee token"])},
    "amount_to_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to deposit to fees"])},
    "get_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get more tokenized gold"])},
    "list_of_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of assets at escrow account"])},
    "assets_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset information is loading"])},
    "asset_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset ID"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset name"])},
    "asset_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset amount"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
    "optin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow account optin action"])},
    "optin_to_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to asset"])},
    "optin_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in"])},
    "withdraw_asset_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw from escrow account"])},
    "withdraw_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset to withdraw"])},
    "withdraw_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to withdraw"])},
    "withdraw_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute withdrawal"])},
    "load_script_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load app execution profile"])},
    "load_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load"])},
    "xgov_promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This work has been performed with support from the Algorand Foundation xGov Grants Program"])}
  }
}