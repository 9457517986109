import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, unref as _unref } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "field grid" }
const _hoisted_3 = { class: "col-12 mb-2 md:col-2 md:mb-0" }
const _hoisted_4 = { class: "col-12 md:col-10" }
const _hoisted_5 = { class: "field grid" }
const _hoisted_6 = {
  for: "guess",
  class: "col-12 mb-2 md:col-2 md:mb-0"
}
const _hoisted_7 = { class: "col-12 md:col-10" }
const _hoisted_8 = { class: "field grid" }
const _hoisted_9 = { class: "col-12 md:col-10" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "field grid" }
const _hoisted_12 = {
  for: "name",
  class: "col-12 mb-2 md:col-2 md:mb-0"
}
const _hoisted_13 = { class: "col-12 md:col-10" }
const _hoisted_14 = { class: "grid mt-2" }
const _hoisted_15 = { class: "col" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "col" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { class: "col" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { key: 1 }
const _hoisted_24 = { class: "col" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { class: "col" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { key: 1 }
const _hoisted_30 = { class: "field grid" }
const _hoisted_31 = {
  for: "name",
  class: "col-12 mb-2 md:col-2 md:mb-0"
}
const _hoisted_32 = { class: "col-12 md:col-10" }

import MainLayout from "../../layouts/Main.vue";
import { onMounted, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import algosdk from "algosdk";
import QRCodeVue3 from "qrcode-vue3";


export default /*@__PURE__*/_defineComponent({
  __name: 'Ed25519',
  setup(__props) {

const state = reactive({
  lastError: "",
  page: "newaccount",
  w: "",
  words: [] as string[],
  a: "",
  showQR: false,
  guess: "",
  challenge: false,
  r: 1,
  s: false,
  addr: "",
  name: "",
  card1Fliped: false,
  card2Fliped: false,
  card3Fliped: false,
  card4Fliped: false,
  card5Fliped: false,
  card1FlipedBefore: false,
  card2FlipedBefore: false,
  card3FlipedBefore: false,
  card4FlipedBefore: false,
  card5FlipedBefore: false,
});

const reset = async () => {
  state.name = "";
  state.lastError = "";
  state.showQR = false;
  state.guess = "";
  state.r = 1;
  state.page = "newaccount";
  state.s = false;
  state.w = "";
  state.addr = "";

  await store.dispatch("wallet/prolong");
  router.push({ name: "Accounts" });
};

const { t } = useI18n(); // use as global scope

const store = useStore();
const router = useRouter();

const createAccount = async () => {
  try {
    state.page = "newaccount";
    let account = algosdk.generateAccount();
    state.a = account.addr;
    state.w = algosdk.secretKeyToMnemonic(account.sk);
    state.words = state.w.split(" ");
  } catch (err: any) {
    const error = err.message ?? err;
    console.error("failed to create account", error, err);
    await store.dispatch("toast/openError", error);
  }
};

const makeRandom = () => {
  state.guess = "";
  state.challenge = true;
  state.r = Math.floor(Math.random() * 25) + 1;
  //state.r = 1;
};

async function skipChallange() {
  try {
    if (
      await store.dispatch("wallet/addPrivateAccount", {
        mn: state.w,
        name: state.name,
      })
    ) {
      router.push("/account/" + state.a);
    }
  } catch (err: any) {
    const error = err.message ?? err;
    console.error("failed to create account", error, err);
    await store.dispatch("toast/openError", error);
  }
}

async function confirmCreate() {
  try {
    if (state.words[state.r - 1] == state.guess.trim()) {
      if (
        await store.dispatch("wallet/addPrivateAccount", {
          mn: state.w,
          name: state.name,
        })
      ) {
        router.push("/account/" + state.a);
      }
    } else {
      await store.dispatch("toast/openError", "Invalid word");
    }

    router.push({ name: "Accounts" });
  } catch (err: any) {
    const error = err.message ?? err;
    console.error("failed to create account", error, err);
    await store.dispatch("toast/openError", error);
  }
}
onMounted(async () => {
  await store.dispatch("wallet/prolong");
  await createAccount();
});

const rotateCard = () => {};

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Password = _resolveComponent("Password")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(MainLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t("newacc.create_basic")), 1),
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          (state.challenge)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("newacc.new_account_challange")), 1),
                  _createElementVNode("div", _hoisted_4, _toDisplayString(state.addr), 1)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("newacc.position_question")) + " " + _toDisplayString(state.r) + "? ", 1),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_InputText, {
                      id: "guess",
                      modelValue: state.guess,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.guess) = $event)),
                      class: "w-full"
                    }, null, 8, ["modelValue"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _cache[11] || (_cache[11] = _createElementVNode("label", { class: "col-12 mb-2 md:col-2 md:mb-0" }, null, -1)),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_Button, {
                      class: "m-1",
                      onClick: confirmCreate
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("newacc.create_account")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Button, {
                      class: "m-1",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (state.challenge = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("global.go_back")), 1)
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (!state.challenge && state.page == 'newaccount')
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t("newacc.create_account_help")), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t("newacc.mnemonic_help")), 1),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t("new_account_shamir.mnemonic")), 1),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_Password, {
                      modelValue: state.w,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((state.w) = $event)),
                      inputClass: "w-full my-1 w-100",
                      class: "w-full",
                      feedback: false,
                      "toggle-mask": true
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_Button, {
                          class: "w-full h-10rem m-2 text-left",
                          severity: 
                      !state.card1FlipedBefore ? 'primary' : 'secondary'
                    ,
                          onClick: _cache[3] || (_cache[3] = ($event: any) => {
                      state.card1Fliped = !state.card1Fliped;
                      state.card1FlipedBefore = true;
                    })
                        }, {
                          default: _withCtx(() => [
                            (!state.card1Fliped)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.$t("newacc.click_to_show_positions")) + " 1 " + _toDisplayString(_ctx.$t("newacc.to")) + " 5 ", 1))
                              : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                  _createElementVNode("div", null, "1: " + _toDisplayString(state.words[0]), 1),
                                  _createElementVNode("div", null, "2: " + _toDisplayString(state.words[1]), 1),
                                  _createElementVNode("div", null, "3: " + _toDisplayString(state.words[2]), 1),
                                  _createElementVNode("div", null, "4: " + _toDisplayString(state.words[3]), 1),
                                  _createElementVNode("div", null, "5: " + _toDisplayString(state.words[4]), 1)
                                ]))
                          ]),
                          _: 1
                        }, 8, ["severity"])
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _createVNode(_component_Button, {
                          class: "w-full h-10rem m-2 text-left",
                          severity: 
                      !state.card2FlipedBefore ? 'primary' : 'secondary'
                    ,
                          onClick: _cache[4] || (_cache[4] = ($event: any) => {
                      state.card2Fliped = !state.card2Fliped;
                      state.card2FlipedBefore = true;
                    })
                        }, {
                          default: _withCtx(() => [
                            (!state.card2Fliped)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.$t("newacc.click_to_show_positions")) + " 6 " + _toDisplayString(_ctx.$t("newacc.to")) + " 10 ", 1))
                              : (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                  _createElementVNode("div", null, "6: " + _toDisplayString(state.words[5]), 1),
                                  _createElementVNode("div", null, "7: " + _toDisplayString(state.words[6]), 1),
                                  _createElementVNode("div", null, "8: " + _toDisplayString(state.words[7]), 1),
                                  _createElementVNode("div", null, "9: " + _toDisplayString(state.words[8]), 1),
                                  _createElementVNode("div", null, "10: " + _toDisplayString(state.words[9]), 1)
                                ]))
                          ]),
                          _: 1
                        }, 8, ["severity"])
                      ]),
                      _createElementVNode("div", _hoisted_21, [
                        _createVNode(_component_Button, {
                          class: "w-full h-10rem m-2 text-left",
                          severity: 
                      !state.card3FlipedBefore ? 'primary' : 'secondary'
                    ,
                          onClick: _cache[5] || (_cache[5] = ($event: any) => {
                      state.card3Fliped = !state.card3Fliped;
                      state.card3FlipedBefore = true;
                    })
                        }, {
                          default: _withCtx(() => [
                            (!state.card3Fliped)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_ctx.$t("newacc.click_to_show_positions")) + " 11 " + _toDisplayString(_ctx.$t("newacc.to")) + " 15 ", 1))
                              : (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                  _createElementVNode("div", null, "11: " + _toDisplayString(state.words[10]), 1),
                                  _createElementVNode("div", null, "12: " + _toDisplayString(state.words[11]), 1),
                                  _createElementVNode("div", null, "13: " + _toDisplayString(state.words[12]), 1),
                                  _createElementVNode("div", null, "14: " + _toDisplayString(state.words[13]), 1),
                                  _createElementVNode("div", null, "15: " + _toDisplayString(state.words[14]), 1)
                                ]))
                          ]),
                          _: 1
                        }, 8, ["severity"])
                      ]),
                      _createElementVNode("div", _hoisted_24, [
                        _createVNode(_component_Button, {
                          class: "w-full h-10rem m-2 text-left",
                          severity: 
                      !state.card4FlipedBefore ? 'primary' : 'secondary'
                    ,
                          onClick: _cache[6] || (_cache[6] = ($event: any) => {
                      state.card4Fliped = !state.card4Fliped;
                      state.card4FlipedBefore = true;
                    })
                        }, {
                          default: _withCtx(() => [
                            (!state.card4Fliped)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_25, _toDisplayString(_ctx.$t("newacc.click_to_show_positions")) + " 16 " + _toDisplayString(_ctx.$t("newacc.to")) + " 20 ", 1))
                              : (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                  _createElementVNode("div", null, "16: " + _toDisplayString(state.words[15]), 1),
                                  _createElementVNode("div", null, "17: " + _toDisplayString(state.words[16]), 1),
                                  _createElementVNode("div", null, "18: " + _toDisplayString(state.words[17]), 1),
                                  _createElementVNode("div", null, "19: " + _toDisplayString(state.words[18]), 1),
                                  _createElementVNode("div", null, "20: " + _toDisplayString(state.words[19]), 1)
                                ]))
                          ]),
                          _: 1
                        }, 8, ["severity"])
                      ]),
                      _createElementVNode("div", _hoisted_27, [
                        _createVNode(_component_Button, {
                          class: "w-full h-10rem m-2 text-left",
                          severity: 
                      !state.card5FlipedBefore ? 'primary' : 'secondary'
                    ,
                          onClick: _cache[7] || (_cache[7] = ($event: any) => {
                      state.card5Fliped = !state.card5Fliped;
                      state.card5FlipedBefore = true;
                    })
                        }, {
                          default: _withCtx(() => [
                            (!state.card5Fliped)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_28, _toDisplayString(_ctx.$t("newacc.click_to_show_positions")) + " 21 " + _toDisplayString(_ctx.$t("newacc.to")) + " 25 ", 1))
                              : (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                  _createElementVNode("div", null, "21: " + _toDisplayString(state.words[20]), 1),
                                  _createElementVNode("div", null, "22: " + _toDisplayString(state.words[21]), 1),
                                  _createElementVNode("div", null, "23: " + _toDisplayString(state.words[22]), 1),
                                  _createElementVNode("div", null, "24: " + _toDisplayString(state.words[23]), 1),
                                  _createElementVNode("div", null, "25: " + _toDisplayString(state.words[24]), 1)
                                ]))
                          ]),
                          _: 1
                        }, 8, ["severity"])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("label", _hoisted_31, _toDisplayString(_ctx.$t("newacc.name")), 1),
                  _createElementVNode("div", _hoisted_32, [
                    _createVNode(_component_InputText, {
                      id: "name",
                      modelValue: state.name,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((state.name) = $event)),
                      class: "w-full"
                    }, null, 8, ["modelValue"])
                  ])
                ]),
                _createVNode(_component_InputText, {
                  modelValue: state.a,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((state.a) = $event)),
                  class: "w-full my-1",
                  disabled: ""
                }, null, 8, ["modelValue"]),
                (!state.showQR)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      severity: "secondary",
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (state.showQR = true)),
                      class: "m-1"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("newacc.show_qr_code")), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (state.showQR)
                  ? (_openBlock(), _createBlock(_unref(QRCodeVue3), {
                      key: 1,
                      width: 500,
                      height: 500,
                      value: state.w,
                      "corners-square-options": { type: 'square', color: '#333' },
                      "corners-dot-options": {
              type: 'square',
              color: '#333',
              gradient: {
                type: 'linear',
                rotation: 0,
                colorStops: [
                  { offset: 0, color: '#333' },
                  { offset: 1, color: '#000' },
                ],
              },
            },
                      "dots-options": {
              type: 'square',
              color: '#333',
              gradient: {
                type: 'linear',
                rotation: 0,
                colorStops: [
                  { offset: 0, color: '#333' },
                  { offset: 1, color: '#000' },
                ],
              },
            }
                    }, null, 8, ["value"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_Button, {
                  class: "m-1",
                  onClick: makeRandom,
                  id: "start_challenge",
                  disabled: !state.name
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("newacc.start_challenge")), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_Button, {
                  class: "m-1",
                  severity: "secondary",
                  onClick: skipChallange,
                  id: "skip_challange",
                  disabled: !state.name
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("newacc.skip_challange")), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_Button, {
                  severity: "secondary",
                  class: "m-1",
                  onClick: createAccount,
                  id: "create_new"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("newacc.create_new")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Button, {
                  severity: "secondary",
                  class: "m-1",
                  onClick: reset,
                  id: "reset"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("newacc.drop_phrase")), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})