import { withAsyncContext as _withAsyncContext, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex align-items-center"
}

import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Dropdown from "primevue/dropdown";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'Select2FAServer',
  props: {
  modelValue: String,
  itemId: String,
},
  emits: ["update:modelValue"],
  async setup(__props, { emit: __emit }) {

let __temp: any, __restore: any

const props = __props;

const emit = __emit;

const model = computed({
  get() {
    return props.modelValue;
  },

  set(value) {
    return emit("update:modelValue", value);
  },
});

const store = useStore();
const { t } = useI18n();

const options = (
  ([__temp,__restore] = _withAsyncContext(() => store.dispatch("publicData/getTwoFactorAuthList", {
  chainId: store.state.config.env,
}))),
  __temp = await __temp,
  __restore(),
  __temp
);

if (options && options.length == 1) {
  model.value = options[0].id;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dropdown), {
    modelValue: model.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
    filter: "",
    options: _unref(options),
    optionLabel: "providerName",
    optionValue: "id",
    placeholder: _ctx.$t('account.select_account'),
    class: "w-full",
    inputClass: "w-full",
    itemid: props.itemId
  }, {
    option: _withCtx((slotProps) => [
      (slotProps.option)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", null, "2FA service by " + _toDisplayString(slotProps.option.providerName), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "options", "placeholder", "itemid"]))
}
}

})