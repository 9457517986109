import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "field grid" }
const _hoisted_2 = {
  for: "accounts",
  class: "col-12 mb-2 md:col-2 md:mb-0"
}
const _hoisted_3 = { class: "col-12 md:col-10" }
const _hoisted_4 = { class: "field grid" }
const _hoisted_5 = {
  for: "accountslist",
  class: "col-12 mb-2 md:col-2 md:mb-0 vertical-align-top h-full"
}
const _hoisted_6 = { class: "col-12 md:col-10" }
const _hoisted_7 = { class: "field grid" }
const _hoisted_8 = {
  for: "threshold",
  class: "col-12 mb-2 md:col-2 md:mb-0"
}
const _hoisted_9 = { class: "col-12 md:col-10" }
const _hoisted_10 = { class: "my-2" }
const _hoisted_11 = { class: "field grid" }
const _hoisted_12 = {
  for: "name",
  class: "col-12 mb-2 md:col-2 md:mb-0"
}
const _hoisted_13 = { class: "col-12 md:col-10" }
const _hoisted_14 = { class: "field grid" }
const _hoisted_15 = { class: "col-12 md:col-10" }

import MainLayout from "../../layouts/Main.vue";
import { onMounted, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import algosdk from "algosdk";
import MultiSelect from "primevue/multiselect";
import Slider from "primevue/slider";


export default /*@__PURE__*/_defineComponent({
  __name: 'Multisig',
  setup(__props) {

const multisigaccts: string[] = [];
const state = reactive({
  addr: "",
  name: "",

  multisignum: 2,
  multisigaccts,
  lastmultisigaccts: multisigaccts,
  friendaccounts: "",
});

const reset = async () => {
  state.name = "";

  state.addr = "";

  await store.dispatch("wallet/prolong");
  router.push({ name: "Accounts" });
};

const { t } = useI18n(); // use as global scope

const store = useStore();
const router = useRouter();
const makeAccounts = () => {
  const accounts = state.friendaccounts.split("\n");
  let accts: string[] = [];

  for (let account of accounts) {
    if (account.length == 58) {
      accts.push(account);
    }
  }
  return accts;
};
const createMultisignClick = async () => {
  try {
    let accts = makeAccounts();
    const mparams = {
      version: 1,
      threshold: state.multisignum,
      addrs: accts,
    };
    await store.dispatch("wallet/addMultiAccount", {
      params: mparams,
      name: state.name,
    });
    router.push({ name: "Accounts" });
  } catch (err: any) {
    const error = err.message ?? err;
    console.error("failed to create account", error, err);
    await store.dispatch("toast/openError", error);
  }
};
onMounted(async () => {
  await store.dispatch("wallet/prolong");
});

const countAccounts = () => {
  return makeAccounts().length;
};

const onAccountsChanged = () => {
  // on add
  for (const acct of state.multisigaccts) {
    if (state.lastmultisigaccts.indexOf(acct) < 0) {
      state.friendaccounts += acct + "\n";
    }
  }

  // on remove
  for (const acct of state.lastmultisigaccts) {
    if (state.multisigaccts.indexOf(acct) < 0) {
      state.friendaccounts = state.friendaccounts.replace(acct + "\n", "");
    }
  }

  state.lastmultisigaccts = state.multisigaccts;
};

return (_ctx: any,_cache: any) => {
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(MainLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t("newacc.create_multisign_account")), 1),
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("newacc.multisig_help")), 1),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("newacc.select_account_from_list")), 1),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_unref(MultiSelect), {
                id: "accounts",
                modelValue: state.multisigaccts,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.multisigaccts) = $event)),
                class: "w-full",
                options: _unref(store).state.wallet.privateAccounts,
                optionLabel: "name",
                optionValue: "addr",
                onChange: onAccountsChanged
              }, null, 8, ["modelValue", "options"])
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("newacc.add_other_accounts")), 1),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Textarea, {
                id: "accountslist",
                modelValue: state.friendaccounts,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((state.friendaccounts) = $event)),
                class: "w-full",
                style: {"min-height":"150px"},
                rows: 10
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("newacc.trashold_help")), 1),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("p", _hoisted_10, "(" + _toDisplayString(state.multisignum) + "/" + _toDisplayString(countAccounts()) + "):", 1),
              _createVNode(_unref(Slider), {
                inputId: "customRange2Slider",
                modelValue: state.multisignum,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((state.multisignum) = $event)),
                class: "w-full",
                min: 1,
                max: countAccounts()
              }, null, 8, ["modelValue", "max"]),
              _createVNode(_component_InputNumber, {
                inputId: "threshold",
                modelValue: state.multisignum,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((state.multisignum) = $event)),
                class: "w-full",
                min: 1,
                max: countAccounts(),
                showButtons: ""
              }, null, 8, ["modelValue", "max"])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t("newacc.name")), 1),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_InputText, {
                id: "name",
                modelValue: state.name,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((state.name) = $event)),
                class: "form-control"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _cache[5] || (_cache[5] = _createElementVNode("label", { class: "col-12 mb-2 md:col-2 md:mb-0" }, null, -1)),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_Button, {
                class: "m-1",
                onClick: createMultisignClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("newacc.create_account")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Button, {
                severity: "secondary",
                class: "m-1",
                onClick: reset
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("global.go_back")), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})