import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "field grid" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "col-12 md:col-10" }
const _hoisted_4 = { class: "field grid" }
const _hoisted_5 = { class: "col-12 md:col-10" }
const _hoisted_6 = { class: "field grid" }
const _hoisted_7 = {
  for: "name",
  class: "col-12 mb-2 md:col-2 md:mb-0"
}
const _hoisted_8 = { class: "col-12 md:col-10" }
const _hoisted_9 = { class: "field grid" }
const _hoisted_10 = { class: "col-12 md:col-10" }

import { useStore } from "vuex";
import MainLayout from "../../layouts/Main.vue";
import { reactive, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import Password from "primevue/password";
import Button from "primevue/button";
import { wordlist } from "@scure/bip39/wordlists/english";
import sha512 from "js-sha512";
import { Shamir } from "@spliterati/shamir";
import algosdk from "algosdk";

import { useRouter } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'Shamir',
  setup(__props) {

const store = useStore();
const router = useRouter();
const { t } = useI18n(); // use as global scope
const state = reactive({
  mn: [""],
  name: "",
});
// from Uint11Array
// https://github.com/algorand/js-algorand-sdk/blob/7965d1c194186e5c7b8a86756c546f2ec35291cd/src/mnemonic/mnemonic.ts#L69
function toUint8Array(buffer11: number[]) {
  const buffer8: Array<number> = [];
  let acc = 0;
  let accBits = 0;
  function add(ui11: number) {
    acc |= ui11 << accBits;
    accBits += 11;
    while (accBits >= 8) {
      buffer8.push(acc & 0xff);
      acc >>= 8;
      accBits -= 8;
    }
  }
  function flush() {
    if (accBits) {
      buffer8.push(acc);
    }
  }

  buffer11.forEach(add);
  flush();
  return new Uint8Array(buffer8);
}

function seedFromMnemonic(mnemonic: string) {
  const words = mnemonic.split(" ");
  const key = words.slice(0, words.length - 1);

  // Check that all words are in list
  for (const w of words) {
    if (wordlist.indexOf(w) === -1)
      throw new Error("NOT_IN_WORDS_LIST_ERROR_MSG");
  }

  const checksum = words[words.length - 1];
  const uint11Array = key.map((word) => wordlist.indexOf(word));

  // Convert the key to uint8Array
  let uint8Array = toUint8Array(uint11Array);
  const data = uint8Array.slice(0, 33);
  // We dont need to chop the last byte - the shamir adds one byte extra to the array

  // compute checksum
  const cs = computeChecksum(data);

  // success!
  if (cs === checksum) return data;
  console.error("cs != checksum", cs, checksum);
  throw new Error("FAIL_TO_DECODE_MNEMONIC_ERROR_MSG");
}

function toUint11Array(buffer8: Uint8Array | number[]) {
  //https://github.com/algorand/js-algorand-sdk/blob/7965d1c194186e5c7b8a86756c546f2ec35291cd/src/mnemonic/mnemonic.ts#L12C1-L34C2
  const buffer11: Array<number> = [];
  let acc = 0;
  let accBits = 0;
  function add(octet: number) {
    acc |= octet << accBits;
    accBits += 8;
    if (accBits >= 11) {
      buffer11.push(acc & 0x7ff);
      acc >>= 11;
      accBits -= 11;
    }
  }
  function flush() {
    if (accBits) {
      buffer11.push(acc);
    }
  }

  buffer8.forEach(add);
  flush();
  return buffer11;
}
function genericHash(arr: sha512.Message) {
  return sha512.sha512_256.array(arr);
}
function applyWords(nums: number[]) {
  return nums.map((n) => wordlist[n]);
}
function computeChecksum(seed: Uint8Array) {
  const hashBuffer = genericHash(seed);
  const uint11Hash = toUint11Array(hashBuffer);
  const words = applyWords(uint11Hash);

  return words[0];
}

const recover = async () => {
  try {
    await store.dispatch("wallet/prolong");

    const bytesArr = state.mn
      .filter((mn) => !!mn)
      .map((mn) => seedFromMnemonic(mn));
    const reassembled = Shamir.combine(bytesArr);
    const mn = algosdk.mnemonicFromSeed(reassembled);

    if (
      await store.dispatch("wallet/addPrivateAccount", {
        mn: mn,
        name: state.name,
      })
    ) {
      router.push({ name: "Accounts" });
    }
  } catch (err: any) {
    const error = err?.message ?? err;
    await store.dispatch("toast/openError", error);
  }
};
onMounted(async () => {
  await store.dispatch("wallet/prolong");
});

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(MainLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t("new_account_shamir.header")), 1),
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("new_account_shamir.help")), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.mn, (mn, index) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("label", {
                for: `mn${index}`,
                class: "col-12 mb-2 md:col-2 md:mb-0"
              }, _toDisplayString(_ctx.$t("new_account_shamir.mnemonic")) + " #" + _toDisplayString(index + 1), 9, _hoisted_2),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(Password), {
                  modelValue: state.mn[index],
                  "onUpdate:modelValue": ($event: any) => ((state.mn[index]) = $event),
                  feedback: false,
                  inputId: `mn${index}`,
                  class: "w-full",
                  inputClass: "w-full",
                  inn: "",
                  toggleMask: ""
                }, null, 8, ["modelValue", "onUpdate:modelValue", "inputId"])
              ])
            ]))
          }), 256)),
          _createElementVNode("div", _hoisted_4, [
            _cache[2] || (_cache[2] = _createElementVNode("label", { class: "col-12 mb-2 md:col-2 md:mb-0" }, null, -1)),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_unref(Button), {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (state.mn.push(''))),
                severity: "secondary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("new_account_shamir.add_mnemnic")), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("new_account_shamir.account_name")), 1),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_InputText, {
                modelValue: state.name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((state.name) = $event)),
                id: "name",
                class: "w-full"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[3] || (_cache[3] = _createElementVNode("label", { class: "col-12 mb-2 md:col-2 md:mb-0" }, null, -1)),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_unref(Button), {
                onClick: recover,
                severity: "primary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("new_account_shamir.recover")), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})